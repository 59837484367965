import React from "react";
const NoUserPage = () => {
  return (
    <div className="bg-slate-100 h-screen flex items-center justify-center">
      <h1>No User Linked :(</h1>
    </div>
  );
};

export default NoUserPage;
