import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import SidebarNav from "./SidebarNav";
import useAuth from "../hooks/useAuth";
import BaseWrapper from "../components/ui/BaseWrapper";

import TopBar from "./TopBar";
export const Layout = () => {
  const { isAuthenticated } = useAuth();
  return (
    <main className="bg-white">
      {!isAuthenticated && <Navbar />}

      {isAuthenticated ? (
        <div className="flex">
          <div className="ml-[110px] w-[94.1%]">
            <TopBar />
            <Outlet />
          </div>
          <SidebarNav />
        </div>
      ) : (
        <Outlet />
      )}
    </main>
  );
};
