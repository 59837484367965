import React from "react";
import { ReactComponent as ReviewIcon } from "../../../assets/company-signup/review-icon.svg";
import { Avatar } from "@mui/material";

const ReviewCard = ({
  description,
  name,
  position,
  avatar,
  avatarCenter = false,
}) => {
  return (
    <div className="review-card relative">
      <div className="absolute left-5 -top-8">
        <ReviewIcon />
      </div>
      <p className="leading-normal text-sm mt-10 ">{description}</p>
      <div className="review-card-divder my-5"></div>
      <div className={`flex items-center ${avatarCenter && "justify-center"}`}>
        <Avatar alt="Remy Sharp" src={avatar} sx={{ width: 56, height: 56 }} />
        <div className="ml-4 mb-1">
          <p className="font-medium leading-normal">{name}</p>
          <p className="text-xs leading-normal">{position}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
