import React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
  brandFontFamily,
  brandInputTextColor,
  brandPrimaryColor,
} from "../../utils/variables";
import useAuth from "../../hooks/useAuth";
import BaseWrapper from "../../components/ui/BaseWrapper";
import DetailsTab from "./Tabs/DetailsTab";
import PayTab from "./Tabs/PayTab";
import ChatTab from "./Tabs/ChatTab";
import ProvidersTab from "./Tabs/ProvidersTab";
import HistoryTab from "./Tabs/HistoryTab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    height: "38px",
    width: "230px",
    fontWeight: 300,
    fontSize: "16px",
    boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.09)",

    borderRadius: "6px",
    // padding: 0,
    margin: "20px 0",
    background: "white",
    marginRight: theme.spacing(1),
    color: brandInputTextColor,
    fontFamily: brandFontFamily,
    "&:hover": {
      /* color: "#40a9ff",
        opacity: 1, */
    },
    "&.Mui-selected": {
      color: brandPrimaryColor,
      fontWeight: 400,
      borderBottom: `1px solid ${brandPrimaryColor}`,
    },
    "&.Mui-focusVisible": {
      //   backgroundColor: "#d1eaff",
    },
  })
);

const TABS = [
  {
    label: "Details",
    component: <DetailsTab />,
    allowedRoles: ["CLIENT", "FREELANCER"],
  },
  {
    label: "Payment",
    component: <PayTab />,
    allowedRoles: ["CLIENT", "FREELANCER"],
  },
  {
    label: "Providers",
    component: <ProvidersTab />,
    allowedRoles: ["CLIENT"],
  },
  {
    label: "Messages",
    component: <ChatTab />,
    allowedRoles: ["CLIENT", "FREELANCER"],
  },
  {
    label: "Engagement",
    component: <h1>Engagement</h1>,
    allowedRoles: ["CLIENT"],
  },
  {
    label: "History",
    component: <HistoryTab />,
    allowedRoles: ["CLIENT"],
  },
];

const WorkJobTabs = () => {
  const { authRole } = useAuth();
  const [value, setValue] = React.useState(0);

  const filteredTabs = TABS.filter((tab) =>
    tab.allowedRoles.includes(authRole)
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <BaseWrapper>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            TabIndicatorProps={{
              style: {
                display: "none", // Hide the indicator
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {filteredTabs.map(
              (tab, index) =>
                tab.allowedRoles.includes(authRole) && (
                  <CustomTab
                    key={index}
                    label={tab.label}
                    {...a11yProps(index)}
                    sx={{
                      minHeight: "38px",
                      marginRight: "40px",
                    }}
                  />
                )
            )}
          </Tabs>
        </Box>
        {filteredTabs.map(
          (tab, index) =>
            tab.allowedRoles.includes(authRole) && (
              <CustomTabPanel value={value} index={index} key={index}>
                {tab.component}
              </CustomTabPanel>
            )
        )}
      </Box>
    </BaseWrapper>
  );
};

export default WorkJobTabs;
