import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLoginSuccessful } from "../store/authUser/authUserSlice";
import { me } from "../services/api";
import { setDataInLocalStorage } from "../utils/helpers";

const FlightboardPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const accessToken = params.get("success");
  const {
    isLoading: meIsLoading,
    error: meApiError,
    data: meData,
  } = useQuery({
    queryKey: ["authUser"],
    queryFn: me,
    enabled: !!accessToken,
  });

  if (accessToken && !meData) {
    setDataInLocalStorage("accessToken", accessToken);
  } else if (accessToken && meData) {
    const payload = {
      user: meData,
      accessToken,
    };
    dispatch(setLoginSuccessful(payload));
  }

  return (
    <div className="flex justify-between">
      <p>FlightboardPage</p>
      <p>FlightboardPage</p>
    </div>
  );
};

export default FlightboardPage;
