import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import "./style.css";
import { TimePicker } from "@mui/x-date-pickers";

export default function BaseDatePicker({ defaultValue, type, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        {type === "date" && (
          <DatePicker
            {...props}
            renderInput={(params) => (
              <TextField label="MM/DD/YYYY" {...params} />
            )}
          />
        )}
        {type === "time" && (
          <TimePicker
            {...props}
            defaultValue={defaultValue}
            renderInput={(params) => (
              <TextField label="MM/DD/YYYY" {...params} />
            )}
          />
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
}
