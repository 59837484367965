import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import PendingAssignment from "../ProvidersTab/PendingAssignment";

import NearbyFreelancers from "../ProvidersTab/NearbyFreelancers";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Providers = () => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [filters, setFilters] = useState(["PENDING"]);
  const [value, setValue] = useState(0);

  return (
    <BaseCard className="py-4 px-12 mt-5 rounded-lg" elevation={1}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="text-base font-normal mr-5"
            label="Nearby"
            {...a11yProps(0)}
          />
          <Tab
            className="text-base font-normal mr-5"
            label="Talent Pool"
            {...a11yProps(1)}
          />
          <Tab
            className="text-base font-normal mr-5"
            label="Worked With"
            {...a11yProps(2)}
          />
          <Tab
            className="text-base font-normal mr-5"
            label="Pending Assignments"
            {...a11yProps(2)}
            onClick={() => {
              setFilters(["PENDING", "ROUTED"]);
            }}
          />
          <Tab
            className="text-base font-normal mr-5"
            label="Assigned"
            {...a11yProps(2)}
            onClick={() => {
              setFilters(["ASSIGNED"]);
            }}
          />
          <Tab
            className="text-base font-normal mr-5"
            label="Decline"
            {...a11yProps(2)}
            onClick={() => {
              setFilters(["DECLINED"]);
            }}
          />

          <Tab
            className="text-base font-normal mr-5"
            label="Hidden"
            {...a11yProps(2)}
            onClick={() => {
              setFilters(["REJECTED"]);
            }}
          />
        </Tabs>
      </Box>
      <div className="mt-5 overflow-x-auto overflow-y-auto rounded-lg">
        <CustomTabPanel value={value} index={0}>
          <NearbyFreelancers filters={filters} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PendingAssignment filters={filters} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PendingAssignment filters={filters} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <PendingAssignment filters={filters} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <PendingAssignment filters={filters} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <PendingAssignment filters={filters} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <PendingAssignment filters={filters} />
        </CustomTabPanel>
      </div>
    </BaseCard>
  );
};

export default Providers;
