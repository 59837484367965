import React from "react";
import ReviewCard from "./components/ReviewCard";

const ReviewSection = ({ reviews = [], avatarCenter, title }) => {
  const getGridCols = () => {
    switch (reviews.length) {
      case 1:
        return "grid-cols-1";
      case 2:
        return "grid-cols-2";
      default:
        return "grid-cols-3";
    }
  };

  return (
    <section className="my-32">
      <h5 className="text-center mb-24 font-normal">{title}</h5>
      <div className={`grid ${getGridCols()} mt-20 gap-16`}>
        {reviews.map((review, index) => (
          <ReviewCard
            avatarCenter={avatarCenter}
            key={index}
            description={review.description}
            name={review.name}
            position={review.position}
            avatar={review.avatar}
          />
        ))}
      </div>
    </section>
  );
};

export default ReviewSection;
