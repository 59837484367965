import React, { useState } from "react";
import Slider from "react-slick";
import { ReactComponent as CompanyColor1 } from "../../assets/company-signup/company-color-1.svg";
import { ReactComponent as CompanyColor2 } from "../../assets/company-signup/company-color-2.svg";
import { ReactComponent as CompanyColor3 } from "../../assets/company-signup/company-color-3.svg";
import { ReactComponent as CompanyColor4 } from "../../assets/company-signup/company-color-4.svg";
import { ReactComponent as CompanyColor5 } from "../../assets/company-signup/company-color-5.svg";

import { ReactComponent as CompanyGray1 } from "../../assets/company-signup/company-gray-1.svg";
import { ReactComponent as CompanyGray2 } from "../../assets/company-signup/company-gray-2.svg";
import { ReactComponent as CompanyGray3 } from "../../assets/company-signup/company-gray-3.svg";
import { ReactComponent as CompanyGray4 } from "../../assets/company-signup/company-gray-4.svg";
import { ReactComponent as CompanyGray5 } from "../../assets/company-signup/company-gray-5.svg";
import HoverMe from "../../components/HoverMe";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  pauseOnHover: true,
  // cssEase: "linear",
};

const COMPANY_LOGOS = [
  {
    displayImage: CompanyGray1,
    changeWithImage: CompanyColor1,
  },
  {
    displayImage: CompanyGray2,
    changeWithImage: CompanyColor2,
  },
  {
    displayImage: CompanyGray3,
    changeWithImage: CompanyColor3,
  },
  {
    displayImage: CompanyGray4,
    changeWithImage: CompanyColor4,
  },
  {
    displayImage: CompanyGray5,
    changeWithImage: CompanyColor5,
  },
];

const CompaniesSlider = () => {
  return (
    <div className="my-28">
      <h5 className="text-center mb-20 font-normal">
        Join Elite Companies Trusting Tech Domain for Success
      </h5>

      <Slider {...settings}>
        {COMPANY_LOGOS.map((logo, index) => (
          <HoverMe
            key={`company-logo-${index}`}
            DisplayImage={logo.displayImage}
            ChangeWithImage={logo.changeWithImage}
          />
        ))}
      </Slider>
    </div>
  );
};

export default CompaniesSlider;
