import React from "react";
import "./style.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ReactComponent as Check } from "../../../assets/global-icons/checked.svg";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import BaseButton from "../../../components/ui/BaseButton";
import Summary from "../Steps/Summary";
import Scheduling from "../Steps/Scheduling";
import WorkPay from "../Steps/WorkPay";
import Assessment from "../Steps/Assessment";
import { useLocation, useNavigate } from "react-router-dom";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#DCDCDC",
    borderTopWidth: 7,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#DCDCDC",
  display: "flex",
  height: 22,
  zIndex: 1,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  "& .QontoStepIcon-circle": {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: "#DCDCDC",
  },
  "& .QontoStepIcon-activeIcon": {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="flex items-center justify-center QontoStepIcon-completedIcon">
          <Check />
        </div>
      ) : active ? (
        <div className="QontoStepIcon-activeIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

//DO NOT CHANGE - steps name are also considered as a path name
const steps = ["summary", "scheduling", "payment", "assessment"];
const component = [<Summary />, <Scheduling />, <WorkPay />, <Assessment />];

const WorkOrderStepper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentStepRoute = location.pathname.split("/")[3]?.toLowerCase();
  const currentStepIndex = steps.indexOf(currentStepRoute);
  const [activeStep, setActiveStep] = React.useState(currentStepIndex);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(activeStep + 1);
    navigate("/work-orders/create/" + steps[activeStep + 1]);

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    navigate("/work-orders/create/" + steps[activeStep - 1]);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => () => {
    navigate("/work-orders/create/" + steps[step]);
    setActiveStep(step);
  };

  return (
    <Box className="mt-6">
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {
            disabled: false,
          };
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} ac {...stepProps} onClick={handleStep(index)}>
              <StepLabel
                {...labelProps}
                StepIconComponent={QontoStepIcon}
                className="cursor-pointer"
              >
                <p
                  className={`font-medium  capitalize ${
                    activeStep === index && "text-primary"
                  }`}
                >
                  {label}{" "}
                  {isStepOptional(index) ? (
                    <Typography variant="caption">(Optional)</Typography>
                  ) : (
                    ""
                  )}
                </p>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <React.Fragment>
        {component[activeStep]}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
          {activeStep !== 0 && (
            <BaseButton
              className="w-28 bg-[#EDEDED] pt-2 pb-2 pl-7 pr-7 rounded-bl-lg  rounded-tl-lg rounded-tr-lg rounded-br-none"
              // disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              <span className="text-dark">Back</span>
            </BaseButton>
          )}
          <Box sx={{ flex: "1 1 auto" }} />
          {/* {isStepOptional(activeStep) && (
              <BaseButton color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </BaseButton>
            )} */}

          {/* <BaseButton onClick={handleNext}></BaseButton> */}

          {activeStep !== steps.length - 1 && (
            <BaseButton
              onClick={handleNext}
              className="w-28 bg-primary  pt-2 pb-2 pl-7 pr-7 rounded-bl-lg  rounded-tl-lg rounded-tr-lg rounded-br-none"
              // disabled={true}
              icon={<i className="fa-solid fa-arrow-right-long text-white"></i>}
              sx={{ mr: 1 }}
            >
              Next
            </BaseButton>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default WorkOrderStepper;
