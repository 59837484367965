import React from "react";
import "./style.css";
import WorkOrderStepper from "./WorkOrderStepper";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import CreateWorkOrderHeader from "./CreateWorkOrderHeader";

const CreateWorkOrder = () => {
  return (
    <div className="bg-[#F6F6F6] h-full">
      <CreateWorkOrderHeader />
      <BaseWrapper>
        <WorkOrderStepper />
      </BaseWrapper>
    </div>
  );
};

export default CreateWorkOrder;
