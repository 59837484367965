import React, { useState } from "react";

import BaseSwitch from "../../../components/ui/BaseSwitch";
import BaseButton from "../../../components/ui/BaseButton";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { createWorkOrder } from "../../../services/api";
import { showToast } from "../../../store/toast/toastSlice";
import { navigateWithReload } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
const WorkOrderHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workOrder = useSelector((state) => state.workOrder);

  const {
    isPending: isPublishLoading,
    status,
    error,
    mutateAsync: workOrderMutation,
  } = useMutation({
    mutationFn: createWorkOrder,
    onSuccess: (data) => {
      console.log("response ", data);
    },
  });

  const handleWorkOrderPublish = async (isDraft) => {
    try {
      const payload = formatWorkOrderPayload(isDraft);
      await workOrderMutation(payload);
      dispatch(
        showToast({
          message: isDraft ? "Work Order Drafted" : "Work Order Published",
          type: isDraft ? "info" : "success",
        })
      );

      navigate(`/work-orders`);
    } catch (error) {
      if (error.response?.status === 401) {
        console.log("logout user");
        window.localStorage.clear();
        navigateWithReload("company-signup");
      }
      if (typeof error.response?.data?.message === "string") {
        dispatch(
          showToast({
            message: error.response?.data?.message,
            type: "error",
          })
        );

        return;
      }

      for (let i = 0; i < error.response.data?.message.length; i++) {
        const errorMessage = error.response.data.message[i];
        dispatch(
          showToast({
            message: errorMessage,
            type: "error",
          })
        );
      }
    }
  };

  const formatWorkOrderPayload = (isDraft) => {
    const formatedWorkOrderServices =
      workOrder.summary.workExperience?.workOrderServices.map((service) => {
        return { serviceId: service };
      });

    const payload = {
      ...workOrder.summary.overview,
      ...workOrder.summary.serviceDescription,
      categoryId: workOrder.summary.workExperience.categoryId,
      workOrderServices: formatedWorkOrderServices,
      ...workOrder.scheduling.schedule,
      ...workOrder.scheduling.location,
      ...workOrder.scheduling.qualification,
      ...workOrder.payment.pay,
      isDraft,
    };

    return payload;
  };

  return (
    <div className="border-b border-zinc-300 bg-white h-[78px]">
      <BaseWrapper>
        <div className="grid grid-cols-7 py-4 items-center ">
          <div className="col-span-2">
            <h3 className="font-light">New Work Order</h3>
          </div>
          <div className="col-span-5 flex  items-center justify-end">
            {/* <div className="flex items-center justify-between bg-[#F6F6F6] p-3 rounded-lg w-52 mr-4 ">
              <BaseSwitch />
              <p>Advanced Fields</p>
            </div> */}
            {/* <div className="mr-4">
              <BaseButton
                variant="contained"
                color="primary"
                style={{
                  border: "1px solid #0F40AE",
                }}
                className="bg-[#F6F6F6] pt-2 pb-2"
              >
                <p className="text-primary text-sm">Auto Dispatch</p>
              </BaseButton>
            </div> */}
            <div className="mr-4">
              <BaseButton
                onClick={() => {
                  handleWorkOrderPublish(true);
                }}
                style={{
                  border: "1px solid #0F40AE",
                }}
                className="bg-[#F6F6F6] pt-[7px] pb-[7px]"
              >
                <p className="text-primary text-sm">Save As Draft</p>
              </BaseButton>
            </div>
            <div>
              <BaseButton
                isLoading={isPublishLoading}
                variant="contained"
                color="primary"
                className="pt-2 pb-2"
                onClick={() => {
                  handleWorkOrderPublish(false);
                }}
              >
                <p className="text-white text-sm">Publish</p>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseWrapper>
    </div>
  );
};

export default WorkOrderHeader;
