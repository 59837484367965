import Button from "@mui/material/Button";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const BaseButton = ({
  children,
  type,
  path = "",
  className,
  variant = "contained",
  disableElevation = true,
  color = "secondary",
  hoverLight = false,
  onClick,
  style,
  icon,
  outlinedHover,
  textSize,
  loaderSize,
  loaderColor = "secondary",
  disabled,
  isLoading = false,
  ...props
}) => {
  return (
    <Button
      style={style}
      fullWidth
      onClick={onClick}
      color={color}
      disableElevation={disableElevation}
      type={type}
      {...props}
      className={`${
        textSize ? textSize : "text-sm"
      } font-light ${className} hover:shadow-lg ease-in duration-900  py-3 px-[18px] ${
        outlinedHover &&
        "border-white hover:bg-slate-50/20 shadow-md hover:shadow-white/50"
      } ${
        hoverLight == true ? "hover:shadow-white/50" : "hover:shadow-black/20"
      }
        
        ${variant === "outlined" ? "hover:text-white hover:bg-" + color : ""}
        
        `}
      variant={variant}
      disabled={disabled ? disabled : isLoading ? true : false}
      endIcon={
        isLoading && <CircularProgress color={loaderColor} size={loaderSize} />
      }
    >
      {!isLoading && children}

      {icon && <span className="ml-2">{icon}</span>}
    </Button>
  );
};

export default BaseButton;
