import React, { useState } from "react";
import BaseModal from "../../components/ui/BaseModal";

import LoginForm from "./LoginForm";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModalOpen } from "../../store/modalsManagement/modalsManagementSlice";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "40px 80px",
  textAlign: "center",
};
const classes = {
  description: { marginBottom: "20px" },
  heading: { marginBottom: "36px" },
};
const Login = () => {
  const dispatch = useDispatch();
  const { loginModalOpen } = useSelector(
    (state) => state.modalsManagement.value
  );

  return (
    <BaseModal
      open={loginModalOpen}
      close={() => dispatch(setLoginModalOpen(false))}
    >
      <div style={style}>
        <h2 style={classes.heading} className="font-500">
          Login to your account
        </h2>
        <LoginForm />
      </div>
    </BaseModal>
  );
};

export default Login;
