import React from "react";

const ServiceCard = ({ ServiceImage, title, description }) => {
  return (
    <div className="relative">
      <div className="image-container">
        <img
          src={ServiceImage}
          className="object-cover rounded-md shadow-lg "
          width="100%"
          height="100%"
        />
      </div>
      <div className="card p-4 min-h-40">
        <p className="mt-3 font-semibold">{title}</p>
        <p className="leading-normal text-base">{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
