import React from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
import { RadioGroup } from "@mui/material";
import BaseRadio from "../../../components/ui/BaseRadio";

const ScheduleForm = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.workOrder.scheduling.schedule);

  const handleChange = (name, value) => {
    console.log(name, value);
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            [name]: value,
          },
        },
      })
    );
  };

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Schedule</h6>
      </div>

      <div className="grid grid-cols-2 gap-y-4">
        <div className="col-span-2">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="HARD_START"
            name="radio-buttons-group"
            onChange={(e) => handleChange("scheduleType", e.target.value)}
          >
            <div>
              <BaseRadio
                label="A specific date & time (Hard Start)"
                value="HARD_START"
              />

              <BaseRadio
                label="Complete Work Between specific hours"
                value="WORK_BETWEEN"
              />

              <BaseRadio
                label="Complete Work anytime over a date range"
                value="WORK_ANYTIME"
              />
            </div>
          </RadioGroup>
        </div>
        <div className="col-span-2 bg-[#F2FAFF] py-3 px-4 rounded-md mb-3">
          <div className="flex ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.1 13.5H9.9V8.1H8.1V13.5ZM9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM8.1 6.3H9.9V4.5H8.1V6.3Z"
                fill="#7AC7FF"
              />
            </svg>

            <div className="ml-2">
              <p className="font-medium ">
                Supplier for this type of work order must reach 15 minutes
                before the work order schedule time.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-2 flex items-center">
          <div className="w-full">
            <BaseDatePicker
              type="date"
              name="orderStartDate"
              value={dayjs(schedule.orderStartDate)}
              onChange={(e) =>
                handleChange("orderStartDate", dayjs(e).format())
              }
            />
          </div>
          <p className="mx-9 text-dark">at</p>
          <div className="w-full">
            <BaseDatePicker
              // defaultValue={dayjs("2022-04-17T00:30:00+05:00")}
              name="orderStartTime"
              type="time"
              value={dayjs(schedule.orderStartTime)}
              onChange={(e) =>
                handleChange("orderStartTime", dayjs(e).format())
              }
            />
          </div>
        </div>
        <div className="col-span-2">
          <BaseSelect
            fullHeight={false}
            fullWidth
            label="Time Zone"
            labelShrink={false}
            placeholder="No clients"
            required={true}
            options={[{ label: "Eastern", value: "EASTERN" }]}
            name="timeZone"
            value={schedule.timeZone}
            onChange={(e) => handleChange("timeZone", e.target.value)}
          />
          <p className="my-2">
            Time zone is set according to the work location.
          </p>
          <p className="text-sm font-medium text-dark mb-4">
            Quick options:
            <span className="text-secondary font-light">
              {" "}
              Tomorrow 8am , 10am , 12pm , 3pm , 6pm , This week , Next week , 2
              Weeks
            </span>
          </p>
        </div>
      </div>
    </BaseCard>
  );
};

export default ScheduleForm;
