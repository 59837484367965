import React from "react";
import BaseTextField from "../../../../components/ui/BaseTextField";

const HalfDayPay = () => {
  return (
    <div className="grid grid-cols-2 gap-y-4 mt-3">
      <div className="mr-10">
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="title"
          name="Fixed Payment"
          label="Half Day Rate"
          optional={false}
          labelShrink={false}
          // value={formik.values.title}
          // onChange={handleChange}
          isDefaultField={false}
        />
      </div>
      <div>
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="title"
          name="maxHours"
          label="Additional Hour Rate"
          optional={false}
          labelShrink={false}
          // value={formik.values.title}
          // onChange={formik.handleChange}

          isDefaultField={false}
        />
        <p className="mt-2 text-sm text-right">
          Additional hourly rate will be applicable after 4 hours of Work
        </p>
      </div>
    </div>
  );
};

export default HalfDayPay;
