import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useRef } from "react";
import BaseTextField from "../ui/BaseTextField";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const LIBRARIES = ["places"];

function SearchLocation({
  onPlaceSelected,
  label = "Search Address",
  isDefaultField = false,
  labelShrink = false,
  value,
  ...props
}) {
  const inputRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: LIBRARIES,
  });

  const handleOnPlacesChanged = () => {
    if (inputRef.current) {
      const places = inputRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        // Extract address components
        const addressComponents = place.address_components.reduce(
          (acc, component) => {
            const type = component.types[0]; // Get the primary type
            if (type === "postal_code") acc.zipCode = component.long_name;
            if (type === "country") acc.country = component.long_name;
            if (type === "locality" || type === "sublocality")
              acc.city = component.long_name;
            if (type === "administrative_area_level_1")
              acc.state = component.long_name;
            if (type === "administrative_area_level_2")
              acc.area = component.long_name;
            return acc;
          },
          {}
        );

        const location = {
          place,
          completeAddress: place.formatted_address,
          latitude,
          longitude,
          ...addressComponents,
        };

        // Call the callback function with the selected place and address components
        if (onPlaceSelected) {
          onPlaceSelected(location);
        }
      }
    }
  };

  return isLoaded ? (
    <StandaloneSearchBox
      onLoad={(ref) => (inputRef.current = ref)}
      onPlacesChanged={handleOnPlacesChanged}
      onClick={(e) => e.stopPropagation()}
    >
      <BaseTextField
        fullWidth
        fullHeight={false}
        label={label}
        placeholder=""
        optional={false}
        value={value}
        labelShrink={labelShrink}
        /* id="displayName"
            name="displayName" */
        {...props}
        isDefaultField={isDefaultField}
      />
    </StandaloneSearchBox>
  ) : (
    <></>
  );
}

export default React.memo(SearchLocation);
