import React from "react";
import ReactQuill from "react-quill";
import "./style.css";

const BaseEditor = ({ value, onChange, className }) => {
  const options = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  return (
    <ReactQuill
      className={className}
      theme="snow"
      value={value}
      onChange={onChange}
      modules={options}
    />
  );
};

export default BaseEditor;
