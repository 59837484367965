import React from "react";
import Login from "../views/Login";

const LoginPage = () => {
  return (
    <div className="bg-slate-100 h-screen">
      <Login />
    </div>
  );
};

export default LoginPage;
