import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { brandPrimaryColor } from "../../../utils/variables";
import BaseButton from "../../../components/ui/BaseButton";

const Sum = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Sum</h6>
      </div>

      <section>
        <BaseCard className="mt-6 p-4 shadow-md" elevation={0}>
          <div className="flex items-center mb-2">
            <p className="min-w-40">Labour Cost</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">$150</p>
          </div>
          <div className="flex items-center mb-2">
            <p className="min-w-40">Expenses Cost</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">$100</p>
          </div>
        </BaseCard>
        <div className="flex items-center mt-6 justify-between bg-[#F6F6F6] px-3 py-1 rounded-md">
          <h6 className="font-normal">Total Estimate</h6>

          <h6 className="font-normal text-secondary">$250.00</h6>
        </div>
      </section>
    </BaseCard>
  );
};

export default Sum;
