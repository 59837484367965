import React from "react";
import "./style.css";
import Service1 from "../../assets/company-signup/service-1.png";
import Service2 from "../../assets/company-signup/service-2.png";
import Service3 from "../../assets/company-signup/service-3.png";
import ServiceCard from "./components/ServiceCard";

const ServicesSection = () => {
  return (
    <div className="grid grid-cols-3 gap-14 mt-64">
      <ServiceCard
        ServiceImage={Service1}
        title="Range of Tech Skills"
        description="Get access to a range of skilled technicians, including Equipment Installation, Cabling, Point of Sale, Wireless Survey, hardware, Equipment de-commissioning, Networking, Wireless Equipment, Site Surveys, Surveillance."
      />
      <ServiceCard
        ServiceImage={Service2}
        title="Worldwide Coverage"
        description="Our portal connects businesses and IT technicians globally, providing comprehensive coverage across all continents. Companies can find expert technicians in any location, ensuring seamless support wherever needed."
      />
      <ServiceCard
        ServiceImage={Service3}
        title="10k+ Technicians"
        description="With over 10,000 highly skilled IT technicians available on our portal, companies have access to a vast network of professionals. Technicians can find numerous opportunities to offer their expertise and grow their careers.  "
      />
    </div>
  );
};

export default ServicesSection;
