import React from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseTextField from "../../../components/ui/BaseTextField";

const Expenses = () => {
  return (
    <div className="d-flex align-items-center">
      <div style={{ width: "540px", marginTop: "16px" }}>
        <BaseSelect
          labelShrink={false}
          fullHeight={false}
          placeholder="Select..."
          isDefaultField={false}
          required={true}
          options={[
            { label: "Hourly", value: "HOURLY" },
            { label: "Full Day", value: "FULL_DAY" },
            { label: "Half Day", value: "HALF_DAY" },
          ]}
          name="requirements"
          value={"HOURLY"}
          // onChange={(e) => handleChange(e, form.id)}
        />
      </div>

      <div style={{ width: "100%", marginRight: "42px", marginLeft: "42px" }}>
        <BaseTextField
          label="Description"
          labelShrink={false}
          fullHeight={false}
          isDefaultField={false}
          required={true}
          optional={false}
          name="requirements"
          value={""}
        />
      </div>
      <div style={{ width: "100%" }}>
        <BaseTextField
          label="Total Amount"
          labelShrink={false}
          fullHeight={false}
          isDefaultField={false}
          required={true}
          optional={false}
          name="requirements"
          value={""}
          // onChange={(e) => handleChange(e, form.id)}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default Expenses;
