import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { brandPrimaryColor } from "../../../utils/variables";
import BaseButton from "../../../components/ui/BaseButton";

const SmartAudit = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-1">
        <h6 className="text-dark">Smart Audit</h6>
      </div>

      <p>No SmartAudit Rule has been added on this work order.</p>
    </BaseCard>
  );
};

export default SmartAudit;
