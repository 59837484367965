import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Qualification = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Work Order Eligibility</h6>
      </div>
      <div>
        <div className="bg-[#F6F6F6] py-2 px-3 rounded-md flex items-center mb-2.5">
          <p className="font-normal w-48">Qualification Type</p>
          <p className="font-light px-1.5 p-[1px]">license</p>
        </div>

        <div className="bg-[#F6F6F6] py-2 px-3 rounded-md flex items-center mb-2.5">
          <p className="font-normal w-48">License</p>
          <p className="font-light bg-[#D9D9D9] rounded-sm px-1.5 p-[1px]">
            Security license
          </p>
          <p className="font-light bg-[#D9D9D9] rounded-sm px-1.5 p-[1px] ml-4">
            low voltage license
          </p>
        </div>
      </div>
    </BaseCard>
  );
};

export default Qualification;
