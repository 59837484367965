import React from "react";
import About from "./About";
import BackgroundVerification from "./BackgroundVerification";
import SkillSet from "./SkillSet";
import Equipments from "./Equipments";

const Profile = ({ user }) => {
  console.log(user);
  return (
    <div className="grid grid-cols-2">
      <div>
        <About />
        <SkillSet />
        <Equipments />
        <BackgroundVerification />
      </div>
    </div>
  );
};

export default Profile;
