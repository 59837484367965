import React, { useState } from "react";

import { ReactComponent as SearchIcon } from "../../assets/global-icons/search.svg";
import { ReactComponent as DollarIcon } from "../../assets/global-icons/dollar.svg";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import BaseDropdown from "../../components/ui/BaseDropdown";
import useAuth from "../../hooks/useAuth";
import { navigateWithReload } from "../../utils/helpers";

const TopBar = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  return (
    <div className="grid grid-cols-12 border-b border-zinc-300 sticky top-0 bg-white z-10">
      <div className="col-span-9 content-center ">
        <div className="flex items-center h-full">
          <div className="py-3 px-4">
            <SearchIcon />
          </div>

          <input className="focus:outline-none h-full w-full p-1" />
        </div>
      </div>
      <div className="col-span-3 flex">
        <div className="grid grid-cols-7 w-full mr-16 items-center justify-end relative">
          <div></div>
          <div className="col-span-2 flex items-center justify-end h-full border-r border-zinc-300 pr-4">
            <DollarIcon />
            <p className="text-sm ml-1 font-normal">50.00</p>
          </div>
          <div className="py-3 px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M10.75 0C5.23 0 0.75 4.48 0.75 10C0.75 15.52 5.23 20 10.75 20C16.27 20 20.75 15.52 20.75 10C20.75 4.48 16.27 0 10.75 0ZM11.75 17H9.75V15H11.75V17ZM13.82 9.25L12.92 10.17C12.2 10.9 11.75 11.5 11.75 13H9.75V12.5C9.75 11.4 10.2 10.4 10.92 9.67L12.16 8.41C12.53 8.05 12.75 7.55 12.75 7C12.75 5.9 11.85 5 10.75 5C9.65 5 8.75 5.9 8.75 7H6.75C6.75 4.79 8.54 3 10.75 3C12.96 3 14.75 4.79 14.75 7C14.75 7.88 14.39 8.68 13.82 9.25Z"
                fill="#636B88"
              />
            </svg>
          </div>
          <div className="py-3 px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="16"
              viewBox="0 0 21 16"
              fill="none"
            >
              <path
                d="M18.75 0H2.75C1.65 0 0.76 0.9 0.76 2L0.75 14C0.75 15.1 1.65 16 2.75 16H18.75C19.85 16 20.75 15.1 20.75 14V2C20.75 0.9 19.85 0 18.75 0ZM18.75 4L10.75 9L2.75 4V2L10.75 7L18.75 2V4Z"
                fill="#636B88"
              />
            </svg>
          </div>
          <div className="py-3 px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="20"
              viewBox="0 0 17 20"
              fill="none"
            >
              <path
                d="M8.75 19.5C9.85 19.5 10.75 18.6 10.75 17.5H6.75C6.75 18.0304 6.96071 18.5391 7.33579 18.9142C7.71086 19.2893 8.21957 19.5 8.75 19.5ZM14.75 13.5V8.5C14.75 5.43 13.11 2.86 10.25 2.18V1.5C10.25 0.67 9.58 0 8.75 0C7.92 0 7.25 0.67 7.25 1.5V2.18C4.38 2.86 2.75 5.42 2.75 8.5V13.5L0.75 15.5V16.5H16.75V15.5L14.75 13.5Z"
                fill="#636B88"
              />
            </svg>
          </div>
          <div
            className="py-3 pl-3"
            onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
          >
            <Avatar
              className="cursor-pointer"
              src="/broken-image.jpg"
              sx={{ width: 24, height: 24 }}
            />
            <BaseDropdown
              open={isProfileDropdownOpen}
              className="w-[236px] top-[50px] shadow-none left-[150px]"
              style={{
                boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div className="hover:bg-[#F6F6F6] py-2 px-1  cursor-pointer border-b rounded-t-lg">
                <p className="capitalize">
                  {auth.user.firstName} {auth.user?.lastName}
                  <span className="text-sm font-medium"> ID: 989832</span>
                </p>
              </div>
              <div
                className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b"
                onClick={() => {
                  navigate("/profile/details");

                  setIsProfileDropdownOpen(false);
                }}
              >
                <p className="capitalize">My Profile</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Package Overview</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Company Setting</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Help & Support</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Developer Center</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Legal</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Privary Policy</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Send Feedback</p>
              </div>
              <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
                <p className="capitalize">Insecure FAQS</p>
              </div>
              <div
                className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer rounded-b-lg"
                onClick={() => {
                  window.localStorage.clear();
                  navigateWithReload("/company-signup");
                }}
              >
                <p className="capitalize">Log Out</p>
              </div>
            </BaseDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
