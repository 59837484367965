import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseEditor from "../../../components/ui/BaseEditor";
import BaseSwitch from "../../../components/ui/BaseSwitch";
import { useDispatch, useSelector } from "react-redux";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
const DescriptionForm = () => {
  const [isConfidential, setIsConfidential] = useState(false);
  const dispatch = useDispatch();
  const serviceDescription = useSelector(
    (state) => state.workOrder.summary.serviceDescription
  );

  const handleChange = (name, value) => {
    dispatch(
      updateWorkOrderForm({
        step: "summary",
        data: {
          serviceDescription: {
            [name]: value,
          },
        },
      })
    );
  };
  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Service Description</h6>
      </div>
      <div className="grid grid-cols-1">
        <div className="col-span-4">
          <div className="flex items-center">
            <p className="font-normal mt-2 mb-3">Work Description</p>
            <p className="ml-2">
              (Provide precise instructions to guarantee your work order is
              completed correctly)
            </p>
          </div>
          <BaseEditor
            className="h-36"
            value={serviceDescription.description}
            onChange={(body) => handleChange("description", body)}
          />

          <div className="flex items-center mt-8  mb-3">
            <p className="font-normal mr-2">Confidential Information</p>
            <BaseSwitch
              secondary={true}
              checked={isConfidential}
              onChange={() => setIsConfidential(!isConfidential)}
            />
            <p className="leading-normal ml-3">
              (<span className="font-medium">Note:</span> Critical information
              will be confidential to you and will not display to providers)
            </p>
          </div>
          {isConfidential && (
            <div className="mb-4">
              <BaseEditor
                className="h-36"
                value={serviceDescription.confidentialInformation}
                onChange={(body) =>
                  handleChange("confidentialInformation", body)
                }
              />
            </div>
          )}
        </div>
      </div>
    </BaseCard>
  );
};

export default DescriptionForm;
