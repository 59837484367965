import { ThemeProvider, THEME_ID, createTheme } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-quill/dist/quill.snow.css";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./layouts/Layout";
import RequireAuth from "./RequireAuth";
import LoginPage from "./pages/LoginPage";
import useAuth from "./hooks/useAuth";
import routes from "./routes.js";
import NoUserPage from "./pages/NoUserPage";
import CompanySignupPage from "./pages/CompanySignupPage";
import FlightboardPage from "./pages/FlightboardPage";

function App() {
  const { userModules, userPermissions } = useAuth();
  const materialTheme = createTheme({
    palette: {
      primary: {
        // main: "#012169",
        main: "#0F40AE",
      },
      secondary: {
        main: "#FF5F00",
      },
      info: {
        main: "#FFFFFF",
      },
    },
    typography: {
      allVariants: {
        fontFamily: ["Poppins", "ui-sans-serif", "system-ui"],
      },
      button: {
        fontFamily: ["Poppins", "ui-sans-serif", "system-ui"],
        textTransform: "none",
        fontSize: "18px",
      },
      textField: {
        color: "blue",
      },
    },

    components: {
      MuiFormLabel: {
        root: {
          color: "#2EFF22",
        },
        styleOverrides: {
          asterisk: {
            color: "#FF5F00",
            fontSize: "22px",
          },
        },
      },
    },
  });

  const filteredRoutes = routes.filter((route) => {
    // Check if the route requires permissions and if the user has those permissions
    const hasRequiredPermissions = route.requiredAccess
      ? Object.entries(route.requiredAccess).every(([permission, required]) => {
          const userPermission = userPermissions?.find(
            (perm) => perm?.module === route.module
          );
          return userPermission
            ? userPermission[permission] === required
            : false;
        })
      : false; // If no requiredAccess, treat it as always having access

    // Check if the route module is included in userModules
    const hasModuleAccess = userModules?.includes(route.module);

    // Combine both conditions to filter the routes
    return hasModuleAccess && hasRequiredPermissions;
  });

  return (
    <ThemeProvider theme={{ [THEME_ID]: materialTheme }}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<LoginPage />} />
          <Route path="company-signup" element={<CompanySignupPage />} />
          <Route path="technician-signup" element={<CompanySignupPage />} />
          <Route path="/" element={<FlightboardPage />} />
          <Route path="not-found" element={<NoUserPage />} />
          <Route path="unauthorized" element={<h1>Unauthorized</h1>} />

          {/* protected routes */}
          <Route element={<RequireAuth allowedModules={filteredRoutes} />}>
            {filteredRoutes.map((route) => (
              <Route
                key={route.module}
                path={route.path}
                element={route.component}
              >
                {route.childs &&
                  route.childs.map((child) => (
                    <Route
                      key={child.path}
                      path={child.path}
                      element={child.component}
                    />
                  ))}
              </Route>
            ))}
          </Route>

          {/* catch all */}
          <Route
            path="*"
            element={
              <h1 className="flex items-center justify-center">Unauthorized</h1>
            }
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
