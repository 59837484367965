import { configureStore } from "@reduxjs/toolkit";
import authUserReducer from "./authUser/authUserSlice";
import modalsManagementReducer from "./modalsManagement/modalsManagementSlice";
import toastReducer from "./toast/toastSlice";
import workOrderReducer from "./workOrder/workOrderSlice";

export const store = configureStore({
  reducer: {
    authUser: authUserReducer,
    modalsManagement: modalsManagementReducer,
    toast: toastReducer,
    workOrder: workOrderReducer,
  },
});
