import React, { useState } from "react";

const HoverMe = ({ DisplayImage, ChangeWithImage }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className="text-center cursor-pointer"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover ? <ChangeWithImage /> : <DisplayImage />}
    </div>
  );
};

export default HoverMe;
