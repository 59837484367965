import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Chat from "../TabsCard/Chat";
import useAuth from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { fetchWorkOrderMessages } from "../../../services/api/message";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

let socket;
const ChatTab = () => {
  const { id } = useParams();
  const { auth } = useAuth();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState(id);
  const dispatch = useDispatch();

  const {
    isLoading: isMessagesDataLoading,
    error: isMessagesDataError,
    data: messagesData,
  } = useQuery({
    queryKey: ["meassgesList"],
    queryFn: () => fetchWorkOrderMessages(id),
    enabled: !!id,
  });

  useEffect(() => {
    setMessages(messagesData?.data);
  }, [messagesData]);

  useEffect(() => {
    socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.emit("joinRoom", roomId);

    socket.on("message", (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    return () => {
      if (socket) socket.disconnect();
    };
  }, [roomId]);

  const sendMessage = () => {
    socket.emit("sendMessage", {
      roomId,
      text: message,
      senderId: auth?.user.id,
    });
    setMessage("");
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <Chat
      sendMessage={sendMessage}
      messages={messages}
      message={message}
      handleMessage={handleMessage}
      authUser={auth?.user}
    />
  );
};

export default ChatTab;
