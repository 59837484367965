import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import BaseButton from "../BaseButton";

export default function BaseCard({
  children,
  className,
  image,
  title,
  description,
  rightButton,
  leftButton,
  elevation = 6,
  ...props
}) {
  return (
    <Card {...props} className={className} elevation={elevation}>
      {image && (
        <CardMedia sx={{ height: 140 }} image={image} title="green iguana" />
      )}
      <CardContent className="p-0">
        {title && (
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}

        {children}
      </CardContent>
      {rightButton ||
        (leftButton && (
          <CardActions>
            {rightButton && <BaseButton>{rightButton}</BaseButton>}
          </CardActions>
        ))}
    </Card>
  );
}
