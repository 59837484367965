import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseButton from "../../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../../utils/variables";

const Pay = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-1 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Payment</h6>
        <span className="text-xs ml-3 text-[#686868]">Blended</span>
      </div>
      <BaseCard className="mt-6 p-4 shadow-md">
        <p className="mb-2">
          Payment Terms{" "}
          <span className="text-secondary text-lg font-normal ml-1">
            15 Days
          </span>
        </p>
        <p className="text-sm">
          Payment for this work order will be processed 15 days after the job is
          completed. If there are any issues related to the work, you will be
          notified within 7 days.
        </p>
      </BaseCard>

      <section className="mt-14">
        <div className="border-bottom mb-4 flex justify-between pb-3 mt-5">
          <h6 className="text-dark">Labour</h6>
        </div>

        <BaseCard className="mt-4" elevation={0}>
          <div className="flex items-center mb-2">
            <p className="min-w-[125px]">Fixed Payment</p>
            <div className="border-t  border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">$100</p>
          </div>
          <div className="flex items-center mb-2">
            <p className="min-w-[100px]">Fixed hours</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">2</p>
          </div>
          <div className="flex items-center mb-2">
            <p className="min-w-[180px]">Additional Hourly rate</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">$50</p>
          </div>
          <div className="flex items-center">
            <p className="min-w-[265px]">Approximate Hours to Complete</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">3</p>
          </div>
        </BaseCard>
      </section>
      <section className="mt-10">
        <div className="border-bottom mb-4 flex justify-between pb-3 mt-5">
          <h6 className="text-dark">Expenses</h6>
        </div>
        <div className="flex items-center mt-4 justify-between">
          <p className="min-w-[250px] font-normal">
            Travel Expense (Fuel Expense)
          </p>
          <div className="border-t border-dotted border-gray-500  w-full"></div>
          <div className="bg-[#FFF0EB] flex items-center  ml-2 px-2 py-1 rounded-md min-w-[170px]">
            <i class="fa-solid fa-check text-secondary mr-2"></i>
            <p className="text-secondary font-normal">Provider: $50.00</p>
          </div>
        </div>
        <div className="flex items-center mt-4 justify-between">
          <p className="min-w-[250px] font-normal">
            Tools Expense (Crimping Tool)
          </p>
          <div className="border-t border-dotted border-gray-500  w-full"></div>
          <div className="bg-[#FFF0EB] flex items-center  ml-2 px-2 py-1 rounded-md ">
            <i class="fa-solid fa-check text-secondary mr-2"></i>
            <p className="text-secondary font-normal">$50.00</p>
          </div>
        </div>
      </section>
    </BaseCard>
  );
};

export default Pay;
