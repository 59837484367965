import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({ text, path, onClick }) => {
  return (
    <Link className="text-gray-950 font-light" to={path} onClick={onClick}>
      {text}
    </Link>
  );
};

export default NavItem;
