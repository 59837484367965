import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseButton from "../../../components/ui/BaseButton";

const Contacts = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Contacts</h6>
      </div>
      <div className="grid grid-cols-2 gap-12 mt-6">
        <div className="bg-light pt-3 pb-6 px-4 rounded-lg">
          <p className="text-lg font-normal mb-1">Work Order Manager</p>
          <p className="mb-1">Muhammad Uzar Khan</p>
          <p className="text-primary mb-1">421 416 696</p>
          <p className="text-primary mb-1">uzair.khan@techdomain.com.au</p>
        </div>
        <div className="bg-light pt-3 pb-4 px-4 rounded-lg">
          <p className="text-lg font-normal mb-1">Secondary Contact</p>
          <p className="mb-1">Hassan</p>
          <p className="text-primary mb-1">421 416 696</p>
          <p className="text-primary mb-1">reza@techdomain.com.au</p>
          <p className="italic text-neutral-400">Not visible to provider</p>
        </div>
      </div>
      <BaseButton
        className="pt-1.5 pb-1.5 text-primary bg-[#EFF4FF] hover:text-white hover:bg-primary mt-8"
        fullWidth={false}
      >
        Add Contact
      </BaseButton>
    </BaseCard>
  );
};

export default Contacts;
