import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/global-icons/remove-icon.svg";
import BaseSwitch from "../../../components/ui/BaseSwitch";

const SmartAuditForm = () => {
  const [isSmartAudit, setIsSmartAudit] = useState(true);
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-2 text-dark">
          Smart Audit{" "}
          <span className="italic text-[#686868] text-base">(Optional)</span>
        </h6>
        <BaseSwitch
          className="ml-4 mb-2"
          secondary={true}
          checked={isSmartAudit}
          onChange={() => setIsSmartAudit(!isSmartAudit)}
        />
      </div>

      {isSmartAudit && (
        <div className="grid grid-cols-2">
          <div className="col-span-2">
            <BaseSelect
              className="mb-3"
              fullHeight={false}
              required={true}
              labelShrink={false}
              label="Smart Audit"
              options={[]}
              name="qualificationType"
              isDefaultField={false}
              // value={formik.values.category}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.category && Boolean(formik.errors.category)}
              // helpertext={formik.touched.category && formik.errors.category}
            />
          </div>
        </div>
      )}
    </BaseCard>
  );
};

export default SmartAuditForm;
