import React, { useState } from "react";
import BaseButton from "../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../utils/variables";
import BaseWrapper from "../../components/ui/BaseWrapper";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";
import { declineOffer } from "../../services/api/offers";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../store/toast/toastSlice";
import CounterOfferModal from "./CounterOfferModal";
import RequestOfferModal from "./RequestOfferModal";

const ActionBar = () => {
  const { authRole, auth } = useAuth();
  const [counterOfferModalOpen, setCounterOfferModalOpen] = useState(false);
  const [requestOfferModalOpen, setRequestOfferModalOpen] = useState(false);
  const dispatch = useDispatch();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const {
    // status,
    error: isDelineError,
    mutateAsync: declineOfferMutation,
  } = useMutation({
    mutationFn: declineOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Updated Successfully",
          type: "success",
        })
      );
    },
  });

  async function handleNotInterestedButton(userId, workOrderId) {
    try {
      let payload = { workOrderId, status: "DECLINED" };
      await declineOfferMutation(payload);
    } catch (error) {
      dispatch(
        showToast({
          message: isDelineError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  return (
    <div className="border-b border-zinc-300 py-3">
      <BaseWrapper>
        <div className="grid grid-cols-8">
          <div className="col-span-6 flex">
            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary text-sm  w-[150px] mr-8 h-9"
              >
                Copy
              </BaseButton>
            )}

            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary text-sm  w-[160px] mr-8 h-9"
              >
                Save as Template
              </BaseButton>
            )}
            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary text-sm  w-[150px] mr-8 h-9"
              >
                Tags
              </BaseButton>
            )}

            {authRole === "FREELANCER" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary text-sm  w-[150px] mr-8 h-9"
                onClick={() =>
                  handleNotInterestedButton(auth.user.id, workOrderDetail.id)
                }
              >
                Not Interested
              </BaseButton>
            )}

            {authRole === "FREELANCER" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary text-sm w-[150px] mr-8 h-9"
              >
                Feedback
              </BaseButton>
            )}

            <BaseButton
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
              className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary text-sm w-[150px] mr-8 h-9"
            >
              Print
            </BaseButton>
            <BaseButton
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary text-sm w-[150px] mr-8 h-9"
            >
              Report problem
            </BaseButton>
            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary text-sm  w-[150px] mr-8 h-9"
              >
                Cancel
              </BaseButton>
            )}
          </div>

          {authRole === "FREELANCER" && (
            <div className="col-span-2 flex justify-end">
              {!workOrderDetail?.isCounterOffersDisable && (
                <BaseButton
                  onClick={() => setCounterOfferModalOpen(true)}
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  className="bg-primary text-white text-sm  w-[150px] mr-8 h-9"
                >
                  Counter Offer
                </BaseButton>
              )}
              <BaseButton
                onClick={() => setRequestOfferModalOpen(true)}
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-primary text-white text-sm  w-[150px] h-9"
              >
                Request
              </BaseButton>
            </div>
          )}

          {authRole === "CLIENT" && (
            <div className="col-span-2 flex justify-end">
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-primary text-white text-sm  w-[180px] ml-8 h-9"
              >
                Mavo to Draft
              </BaseButton>
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-primary text-white text-sm  w-[150px] ml-8 h-9"
              >
                Unpublish
              </BaseButton>
            </div>
          )}
        </div>
      </BaseWrapper>

      <RequestOfferModal
        requestOfferModalOpen={requestOfferModalOpen}
        setRequestOfferModalOpen={setRequestOfferModalOpen}
      />
      <CounterOfferModal
        counterOfferModalOpen={counterOfferModalOpen}
        setCounterOfferModalOpen={setCounterOfferModalOpen}
      />
    </div>
  );
};

export default ActionBar;
