import React from "react";

const WorkOrderStatus = ({ status, textSize = "text-xs" }) => {
  const getStatus = () => {
    if (status === "PUBLISHED") {
      return (
        <p
          className={`text-[#573CA9] w-20 bg-[#DFD4FF] text-center py-[3px] rounded-sm ${textSize}`}
        >
          Published
        </p>
      );
    } else if (status === "DRAFT") {
      return (
        <p
          className={`text-[#804C1C] w-20 bg-[#F6D2B1] text-center py-[3px] rounded-sm ${textSize}`}
        >
          Draft
        </p>
      );
    } else if (status === "ROUTED") {
      return (
        <p
          className={`text-[#328294] w-20 bg-[#B6EDFA] text-center py-[3px] rounded-sm ${textSize}`}
        >
          Routed
        </p>
      );
    } else if (status === "COUNTER") {
      return (
        <p
          className={`text-[#FF5F00] w-20 bg-[#FFEBDF] text-center py-[3px] rounded-sm ${textSize}`}
        >
          Counter
        </p>
      );
    } else if (status === "DECLINED") {
      return (
        <p
          className={`text-[#933434] w-20 bg-[#F9B8B8] text-center py-[3px] rounded-sm ${textSize}`}
        >
          Decline
        </p>
      );
    } else if (status === "ASSIGNED") {
      return (
        <p
          className={`text-[#349D6C] w-20 bg-[#BDF5DB] text-center py-[3px] rounded-sm ${textSize}`}
        >
          Assigned
        </p>
      );
    }
  };
  return getStatus();
};

export default WorkOrderStatus;
