import React from "react";
import OverviewForm from "../Forms/OverviewForm";
import DescriptionForm from "../Forms/DescriptionForm";
import WorkExperienceForm from "../Forms/WorkExperienceForm";
import DocumentsForm from "../Forms/DocumentsForm";
import QualificationForm from "../Forms/QualificationForm";

const Summary = () => {
  return (
    <div>
      <OverviewForm />
      <DescriptionForm />
      <QualificationForm />
      <WorkExperienceForm />
      <DocumentsForm />
    </div>
  );
};

export default Summary;
