import React from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";

const Schedule = () => {
  return (
    <div className="d-flex align-items-center">
      <div style={{ width: "540px", marginTop: "16px" }}>
        <BaseSelect
          labelShrink={false}
          fullHeight={false}
          placeholder="Select..."
          isDefaultField={false}
          required={true}
          options={[
            { label: "Hourly", value: "HOURLY" },
            { label: "Full Day", value: "FULL_DAY" },
            { label: "Half Day", value: "HALF_DAY" },
          ]}
          name="requirements"
          value={"HOURLY"}
          // onChange={(e) => handleChange(e, form.id)}
        />
      </div>

      <div style={{ width: "100%", marginRight: "42px", marginLeft: "42px" }}>
        <p style={{ fontWeight: 400 }}>Arrives</p>
        <div
          style={{
            background: "white",
            boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "6px",
          }}
        >
          <BaseDatePicker
            type="date"
            name="orderStartDate"
            //   value={dayjs(schedule.orderStartDate)}
            //   onChange={(e) =>
            //     handleChange("orderStartDate", dayjs(e).format())
            //   }
          />
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <p style={{ fontWeight: 400 }}>Start at</p>
        <div
          style={{
            background: "white",
            boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "6px",
          }}
        >
          <BaseDatePicker
            type="time"
            name="orderStartDate"
            //   value={dayjs(schedule.orderStartDate)}
            //   onChange={(e) =>
            //     handleChange("orderStartDate", dayjs(e).format())
            //   }
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
