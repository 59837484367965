import React, { useState } from "react";
import BaseButton from "../../components/ui/BaseButton";
import BaseWrapper from "../../components/ui/BaseWrapper";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import NavItem from "./NavItem";
import { useDispatch } from "react-redux";
import { setLoginModalOpen } from "../../store/modalsManagement/modalsManagementSlice";
import Login from "../../views/Login";

const Navbar = () => {
  const dispatch = useDispatch();
  return (
    <BaseWrapper>
      <nav className="grid grid-cols-3 py-[9px]">
        <div className="flex items-center justify-between col-span-2 mr-36">
          <Link to="/" className="mt-1">
            <Logo />
          </Link>

          <NavItem text="Find techs" />
          <NavItem text="Find works" />
          <NavItem text="Service coverage" />
          <NavItem text="Resources" />
          <NavItem text="About" />
          <NavItem text="Chat" path="chat" />
        </div>
        <div className="flex items-center justify-end col-span-1">
          <Link to="company-signup">
            <BaseButton>
              <p className="text-xs font-semibold text-white">Get started</p>
            </BaseButton>
          </Link>

          <div className="flex items-center justify-between w-44 ml-14">
            <NavItem
              text="Login"
              onClick={() => dispatch(setLoginModalOpen(true))}
            />

            <p className="text-gray-950">|</p>

            <NavItem text="Help center" path="company-signup" />
          </div>
        </div>
      </nav>
      <Login />
    </BaseWrapper>
  );
};

export default Navbar;
