import React from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BaseButton from "../BaseButton";

const DropzoneContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "14px 24px",
  border: `2px dashed #C4CAD4`,
  backgroundColor: "#F8F9FC",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    // backgroundColor: theme.palette.action.hover,
  },
}));

const DropzoneIcon = styled(CloudUploadIcon)(({ theme }) => ({
  fontSize: 48,
  //   marginBottom: theme.spacing(2),
  //   color: theme.palette.primary.main,
}));

const DropzoneText = styled(Typography)(({ theme }) => ({
  //   color: theme.palette.text.secondary,
}));

const BaseDragDrop = ({ onDrop, children }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />

      {children}
    </DropzoneContainer>
  );
};

export default BaseDragDrop;
