import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/global-icons/remove-icon.svg";
import BaseSwitch from "../../../components/ui/BaseSwitch";
import { useDispatch } from "react-redux";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";

const qualificationFormat = {
  id: Date.now(),
  qualificationType: "Licenses",
  requirements: [],
};
const QualificationForm = () => {
  const dispatch = useDispatch();
  const [isWorkQualification, setIsWorkQualification] = useState(true);
  const [listOfQualifications, setListOfQualifications] = useState([
    qualificationFormat,
  ]);

  const handleChange = (event, id) => {
    const { name, value } = event.target;

    const updatedQualifications = listOfQualifications.map((item) => {
      if (item.id === id) {
        if (name === "qualificationType") {
          return { ...item, qualificationType: value };
        } else if (name === "requirements") {
          return {
            ...item,
            requirements: Array.isArray(value) ? value : [value],
          };
        }
      }
      return item;
    });
    setListOfQualifications(updatedQualifications);

    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          qualification: {
            requiredQualifications: updatedQualifications,
          },
        },
      })
    );
  };

  const removeItem = (id) => {
    setListOfQualifications((prevArray) =>
      prevArray.filter((item) => item.id !== id)
    );
  };

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-2 text-dark">
          Work Order Eligibility{" "}
          <span className="text-base italic text-[#686868]">(Optional)</span>
        </h6>
        {/* <p>(Optional)</p> */}
        <BaseSwitch
          className="ml-4 mb-2"
          secondary={true}
          checked={isWorkQualification}
          onChange={() => setIsWorkQualification(!isWorkQualification)}
        />
      </div>

      {isWorkQualification && (
        <div className="grid grid-cols-2">
          <div className="col-span-2">
            <p className="leading-normal mb-4">
              Qualifications may encompass various elements such as licenses,
              certifications, insurance, equipment, screenings, and specific
              selection criteria. If any qualifications are absent from a
              requesting provider's profile, you will be prompted to confirm the
              assignment.
            </p>
          </div>

          {listOfQualifications.map((form, index) => (
            <div
              key={form.id}
              className="col-span-2 grid grid-cols-2 gap-6 px-5 py-2 rounded-lg mb-4"
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <div className="col-span-2 flex items-center">
                <div
                  className="mr-5 mt-3 cursor-pointer"
                  onClick={() => removeItem(form.id)}
                >
                  <RemoveIcon />
                </div>
                <div className="w-full mr-10">
                  <BaseSelect
                    className="mb-3"
                    fullHeight={false}
                    required={true}
                    labelShrink={false}
                    label="Qualification Type"
                    options={[
                      {
                        label: "Licenses",
                        value: "Licenses",
                      },
                      {
                        label: "Certificates",
                        value: "Certificates",
                      },
                    ]}
                    name="qualificationType"
                    isDefaultField={false}
                    value={form.qualificationType}
                    onChange={(e) => handleChange(e, form.id)}
                  />
                </div>
                <div className="w-full">
                  <BaseSelect
                    labelShrink={false}
                    label={form.qualificationType}
                    fullHeight={false}
                    placeholder="Select..."
                    isDefaultField={false}
                    required={true}
                    options={[]}
                    name="requirements"
                    multiple
                    value={form.requirements}
                    onChange={(e) => handleChange(e, form.id)}
                  />
                </div>
              </div>
            </div>
          ))}
          <div
            className="flex items-center mt-2 cursor-pointer col-span-2"
            onClick={() =>
              setListOfQualifications((prevArray) => [
                ...prevArray,
                { ...qualificationFormat, id: Date.now() }, // Ensure each new item gets a unique id
              ])
            }
          >
            <PlusIcon />

            <p className="text-secondary ml-2"> Add Qualification</p>
          </div>
        </div>
      )}
    </BaseCard>
  );
};

export default QualificationForm;
