import React from "react";
import UserDetailsCard from "./UserDetailsCard";
import LocationCard from "./LocationCard";
import TimeLoggedCard from "./TimeLoggedCard";
import TitleCard from "./TitleCard";
import BaseWrapper from "../../components/ui/BaseWrapper";
import { useSelector } from "react-redux";
import ScheduleCard from "./ScheduleCard";
import useAuth from "../../hooks/useAuth";

const WorkJobDetailsHeader = () => {
  const { authRole } = useAuth();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  return (
    <div className="border-b border-zinc-300 py-6 sticky top-[49px] bg-white z-10">
      <BaseWrapper>
        <div className="grid grid-cols-7 items-center">
          <div className="col-span-2 h-full w-full flex items-center">
            <TitleCard
              publishedDate={workOrderDetail?.createdAt}
              title={workOrderDetail?.title}
              id={workOrderDetail?.id}
              status={workOrderDetail?.status}
            />
          </div>

          <div className="col-span-5">
            <div className="grid grid-cols-3 gap-10">
              {/* {authRole === "FREELANCER" ? ( */}
              <ScheduleCard />

              {/* )  */}

              {/* : ( */}
              {/* <TimeLoggedCard /> */}
              {/* )} */}
              <LocationCard location={workOrderDetail?.address} />
              <UserDetailsCard
                authRole={authRole}
                createdBy={workOrderDetail?.createdBy}
                workOrderManager={workOrderDetail?.workOrderManager}
                offersCount={workOrderDetail?.offersCount}
              />
            </div>
          </div>
        </div>
      </BaseWrapper>
    </div>
  );
};

export default WorkJobDetailsHeader;
