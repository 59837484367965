import React from "react";
import Pay from "../TabsCard/Pay";
import Sum from "../TabsCard/Sum";
import LocationMap from "../TabsCard/LocationMap";
import { useSelector } from "react-redux";
import Contacts from "../TabsCard/Contacts";
import Schedule from "../TabsCard/Schedule";

const PayTab = () => {
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  return (
    <div className="grid grid-cols-2 gap-2">
      <div>
        <Pay />
        <Sum />
      </div>
      <div>
        <Schedule />
        <LocationMap location={workOrderDetail?.address} />
        <Contacts />
      </div>
    </div>
  );
};

export default PayTab;
