import React from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { useDispatch, useSelector } from "react-redux";

import BaseCheckbox from "../../../components/ui/BaseCheckbox";
import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
import SearchLocation from "../../../components/SearchLocation/SearchLocation";

const LocationForm = () => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.workOrder.scheduling.location);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          location: {
            [name]: type === "checkbox" ? checked : value,
          },
        },
      })
    );
  };

  const handlePlaceSelected = (selectedAddress) => {
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          location: {
            country: selectedAddress.country,
            addressLine1: selectedAddress.completeAddress,
            city: selectedAddress.city,
            state: selectedAddress.state,
            zipCode: selectedAddress.zipCode,
            longitude: selectedAddress.longitude,
            latitude: selectedAddress.latitude,
          },
        },
      })
    );
  };

  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-10 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Location</h6>
      </div>

      <div className="grid grid-cols-2 gap-y-4">
        <div className="col-span-2">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="test"
            name="radio-buttons-group"
          >
            <div>
              <BaseRadio label="Specify exact address" value="test" />

              <BaseRadio label="Use saved location" value="test1" />

              <BaseRadio label="Remote (no address)" value="test2" />
            </div>
          </RadioGroup>
        </div>
        <div className="mr-10 mt-2">
          <BaseTextField
            fullWidth
            fullHeight={false}
            label="Display name"
            hint="(The display name will be shared with the assigned provider)"
            optional={false}
            labelShrink={false}
            className="col-span-4"
            value={location.displayName}
            id="displayName"
            name="displayName"
            onChange={handleChange}
            isDefaultField={false}
          />
        </div>
        <div>
          <BaseSelect
            fullHeight={false}
            fullWidth
            label="Location type"
            labelShrink={false}
            placeholder="Location type"
            required={true}
            isDefaultField={false}
            options={[
              { label: "Commercial", value: "COMMERCIAL" },
              { label: "Residential", value: "RESIDENTIAL" },
            ]}
            id="locationType"
            name="locationType"
            value={location.locationType}
            onChange={handleChange}
          />
        </div>
        <div className="mr-10">
          <SearchLocation onPlaceSelected={handlePlaceSelected} />
        </div>

        <div>
          <BaseTextField
            fullHeight={false}
            fullWidth
            label="Country"
            labelShrink={false}
            optional={false}
            /* options={[
              {
                label: "United States of America",
                value: "United States of America",
              },
            ]} */
            id="country"
            name="country"
            value={location.country}
            onChange={handleChange}
            isDefaultField={false}
          />
        </div>

        <div className="mr-10">
          <BaseTextField
            fullWidth
            fullHeight={false}
            label="Address Line"
            optional={false}
            labelShrink={false}
            id="addressLine1"
            name="addressLine1"
            value={location.addressLine1}
            onChange={handleChange}
            isDefaultField={false}
          />
        </div>

        <div>
          <BaseTextField
            fullWidth
            fullHeight={false}
            label="City"
            optional={false}
            labelShrink={false}
            id="city"
            name="city"
            value={location.city}
            onChange={handleChange}
            isDefaultField={false}
          />
        </div>

        <div className="mr-10">
          <BaseTextField
            label="State"
            fullHeight={false}
            labelShrink={false}
            optional={false}
            //   options={[{ label: "No project", value: null }]}
            id="state"
            name="state"
            value={location.state}
            onChange={handleChange}
            isDefaultField={false}
          />
        </div>

        <div>
          <BaseTextField
            fullWidth
            fullHeight={false}
            label="Zip Code"
            optional={false}
            labelShrink={false}
            isDefaultField={false}
            id="zipCode"
            name="zipCode"
            value={location.zipCode}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-2">
          <BaseCheckbox
            label="Save this location for future use"
            id="saveForFuture"
            name="saveForFuture"
            checked={location.saveForFuture}
            onChange={handleChange}
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default LocationForm;
