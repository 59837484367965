import React from "react";
import "./style.css";

const HeroSection = ({
  mainHeading,
  secondaryHeading,
  HeroSectionImg,
  rightBackgroundImg,
  backgroundColor = "rgba(0, 0, 255, 0.5)", // Default blue color
  textColor,
}) => {
  return (
    <section
      id="hero-section"
      className="grid grid-cols-12 background-container"
      style={{
        backgroundImage: `url(${rightBackgroundImg})`,
      }}
    >
      <div className="flex items-center justify-center col-span-6 hero-content">
        <div className="relative w-full">
          <div className="absolute -top-36 -right-12 ">
            <h1
              style={{ whiteSpace: "pre-line", color: textColor }}
              className="text-white tracking-wider"
            >
              {mainHeading}
            </h1>
            <h5
              style={{ whiteSpace: "pre-line", color: textColor }}
              className="text-white tracking-widest mt-5"
            >
              {secondaryHeading}
            </h5>
          </div>
        </div>
      </div>

      <div className="parallelogram-container col-span-6">
        <div
          className="parallelogram bg-cover bg-top"
          style={{ backgroundImage: `url(${HeroSectionImg})` }}
        ></div>
        <div className="small-parallelogram"></div>
      </div>

      <div
        className="overlay"
        style={{
          background: backgroundColor,
        }}
      ></div>
    </section>
  );
};

export default HeroSection;
