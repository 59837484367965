export const brandName = "Tech Domain";
export const brandFontFamily = "Poppins";
export const brandInputTextColor = "#686868";
export const brandSecondaryColor = "#FF5F00";
export const brandPrimaryColor = "#0F40AE";
export const ROLES = {
  FREELANCE: "FREELANCER",
  CLIENT: "CLIENT",
  ADMIN: "ADMIN",
};
