import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseTextField from "../../components/ui/BaseTextField";
import BaseButton from "../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../services/api";
import { useDispatch } from "react-redux";
import { setLoginSuccessful } from "../../store/authUser/authUserSlice";
import BaseCheckbox from "../../components/ui/BaseCheckbox";
import { ReactComponent as GoogleIcon } from "../../assets/social-icons/google.svg";
import { ReactComponent as MicrosoftIcon } from "../../assets/social-icons/microsoft.svg";
import { showToast } from "../../store/toast/toastSlice";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const LoginForm = () => {
  const dispatch = useDispatch();

  const {
    isPending: isLoginLoading,
    status,
    error,
    mutateAsync: loginMutation,
  } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      const payload = {
        user: data.data,
        accessToken: data.accessToken,
      };
      dispatch(setLoginSuccessful(payload));
    },
  });

  /*  if (error) {
    dispatch(
      showToast({
        message: error.response.data.message,
        type: "error",
      })
    );
  } */

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleSubmit = async (values) => {
    try {
      await loginMutation(values);
      dispatch(
        showToast({
          message: "Login Successfully",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error.response?.data?.message || "An error occurred",
          type: "error",
        })
      );
    }
  };

  return (
    <form style={{ width: "100%" }}>
      <BaseTextField
        style={{ width: "100%", marginBottom: "32px" }}
        id="email"
        name="email"
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <BaseTextField
        style={{ width: "100%" }}
        id="password"
        name="password"
        label="Password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />

      <div className="d-flex content-between remember-fw-wrapper">
        <div className="d-flex align-items-center">
          <BaseCheckbox style={{ marginRight: "0" }} />
          <p>Remember Me</p>
        </div>
        <p className="forgot-password">Forgot Password?</p>
      </div>

      <BaseButton
        fullWidth
        isLoading={isLoginLoading}
        className="mt-7"
        variant="contained"
        type="submit"
        onClick={formik.handleSubmit}
      >
        Login
      </BaseButton>

      <p className="not-registered">
        not registered yet?{" "}
        <span className="forgot-password"> Create an account</span>
      </p>
      <div className="separator">
        <span className="line"></span>
        <span className="or-text">OR</span>
        <span className="line"></span>
      </div>
      <BaseButton
        hoverLight={true}
        fullWidth
        disableElevation={false}
        className="mt-4 bg-orange-700	"
        color="info"
        variant="contained"
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_BASE_URL}auth/google`)
        }
      >
        <div className="d-flex  align-items-center width-full">
          <div style={{ width: "26px", height: "26px" }}>
            <GoogleIcon />
          </div>
          <div className="d-flex content-center width-full">
            <p style={{ textAlign: "center" }}>Continue with Google</p>
          </div>
        </div>
      </BaseButton>
      <BaseButton
        hoverLight={false}
        style={{ marginTop: "20px" }}
        fullWidth
        disableElevation={false}
        className="mt-4 bg-orange-700	"
        color="info"
        variant="contained"
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_BASE_URL}auth/microsoft`)
        }
      >
        <div className="d-flex  align-items-center width-full">
          <div style={{ width: "26px", height: "26px" }}>
            <MicrosoftIcon />
          </div>
          <div className="d-flex content-center width-full">
            <p style={{ textAlign: "center" }}>Continue with Microsoft</p>
          </div>
        </div>
      </BaseButton>
    </form>
  );
};

export default LoginForm;
