import React, { useState } from "react";
import BaseButton from "../../../components/ui/BaseButton";
import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import BaseModal from "../../../components/ui/BaseModal";
import { brandPrimaryColor } from "../../../utils/variables";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "./style.css";
import BaseDataTable from "../../../components/ui/BaseDataTable";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseCheckbox from "../../../components/ui/BaseCheckbox";
import BaseSelect from "../../../components/ui/BaseSelect";
import { createOffer } from "../../../services/api/offers";
import { showToast } from "../../../store/toast/toastSlice";
import { useMutation } from "@tanstack/react-query";
import useAuth from "../../../hooks/useAuth";

const RequestOfferModal = ({
  requestOfferModalOpen,
  setRequestOfferModalOpen,
}) => {
  const dispatch = useDispatch();
  const { auth } = useAuth;
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const {
    isPending: isCreateOfferLoading,
    status,
    error: isCreateOfferError,
    mutateAsync: createOfferMutation,
  } = useMutation({
    mutationFn: createOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Offer Sent Successfully",
          type: "success",
        })
      );
    },
  });

  const data = [
    {
      id: 1,
      title: "Test",
      payAmount: 500,
      status: "Missing",
    },
    {
      id: 2,
      title: "Test",
      payAmount: 500,
      status: "Passed",
    },
  ];

  const columnWidths = {};

  const COLUMNS = [
    {
      label: "Type",
      renderCell: (item) => (
        <p
          style={{
            color: "#0F40AE",
            fontSize: "0.875rem",
          }}
          className="text-sm font-normal text-primary"
        >
          Type of Work
        </p>
      ),
      pinLeft: false,
    },
    {
      label: "Qualification",
      renderCell: (item) => (
        <>
          <p
            style={{ fontSize: "0.875rem" }}
            className="text-sm text-primary cursor-pointer"
          >
            Networking
          </p>
        </>
      ),
    },

    {
      label: "Status",
      renderCell: (item) => (
        <p
          style={{
            color: item.status === "Missing" ? "#CD3745" : "#FF5D20",
            fontSize: "0.875rem",
          }}
          className="text-primary text-xs"
        >
          {item.status}
        </p>
      ),
    },
    {
      label: "Action",
      renderCell: (item) => (
        <p
          style={{
            color: "#0F40AE",
            fontSize: "0.875rem",
            cursor: "pointer",
          }}
        >
          Add Eduipment to profile
        </p>
      ),
    },
  ];

  const handleRequestButton = async () => {
    try {
      await createOfferMutation({
        workOrderId: workOrderDetail?.id,
        userId: auth?.user.id,
      });
      setRequestOfferModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isCreateOfferError?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <BaseModal
      style={{
        width: "1200px",
      }}
      open={requestOfferModalOpen}
      close={() => {}}
    >
      <div className="container">
        <h6
          className="text-dark border-bottom"
          style={{ marginBottom: "24px", paddingBottom: "4px" }}
        >
          Missing Qualifications
        </h6>
        <div>
          <BaseDataTable
            columns={COLUMNS}
            nodes={data}
            columnWidths={columnWidths}
            lastColFix={false}
          />
        </div>
        <div
          style={{
            background: "#F6F6F6",
            borderRadius: "6px",
            marginTop: "40px",
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "40px",
          }}
        >
          <p
            style={{
              fontSize: "1.125rem",
              borderBottom: "1px solid #DFDFDF",
            }}
          >
            Set Your Start Time
          </p>
          <div className="d-flex content-between c-mt-2">
            <div style={{ width: "330px" }}>
              <p style={{ fontWeight: 400 }}>Date</p>
              <div
                style={{
                  background: "white",
                  boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                  borderRadius: "6px",
                }}
              >
                <BaseDatePicker
                  type="date"
                  name="orderStartDate"
                  //   value={dayjs(schedule.orderStartDate)}
                  //   onChange={(e) =>
                  //     handleChange("orderStartDate", dayjs(e).format())
                  //   }
                />
              </div>
            </div>
            <div style={{ width: "330px" }}>
              <p style={{ fontWeight: 400 }}>Time</p>
              <div
                style={{
                  background: "white",
                  boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                  borderRadius: "6px",
                }}
              >
                <BaseDatePicker
                  type="time"
                  name="orderStartDate"
                  //   value={dayjs(schedule.orderStartDate)}
                  //   onChange={(e) =>
                  //     handleChange("orderStartDate", dayjs(e).format())
                  //   }
                />
              </div>
            </div>
            <div style={{ width: "330px" }}>
              <p style={{ fontWeight: 400 }}>Duration</p>
              <BaseTextField
                optional={false}
                isDefaultField={false}
                labelShrink={false}
                endAdornment="Hours"
                //   value={dayjs(schedule.orderStartDate)}
                //   onChange={(e) =>
                //     handleChange("orderStartDate", dayjs(e).format())
                //   }
              />
            </div>
          </div>
          <div className="d-flex align-items-center c-mt-4">
            <BaseCheckbox />
            <p>Send Requuest Expiry time</p>
            <div>
              <BaseSelect
                style={{
                  width: "150px",
                  height: "40px",
                  background: "white",
                  marginLeft: "10px",
                }}
                value={30}
                options={[
                  {
                    label: "30 minutes",
                    value: 30,
                  },
                ]}
                isDefaultField={false}
              />
            </div>
          </div>
        </div>

        <div className="d-flex content-center" style={{ marginTop: "60px" }}>
          <BaseButton
            className="request-button bg-light"
            style={{
              marginRight: "16px",
              color: "#686868",
              width: "150px",
            }}
            onClick={() => setRequestOfferModalOpen(false)}
          >
            Cancel
          </BaseButton>

          <BaseButton
            loaderSize={24}
            loaderColor="primary"
            isLoading={isCreateOfferLoading}
            className="request-button"
            color="primary"
            style={{
              width: "150px",
            }}
            onClick={handleRequestButton}
          >
            Send a Request
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default RequestOfferModal;
