import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/global-icons/remove-icon.svg";
import BaseSwitch from "../../../components/ui/BaseSwitch";

const ProviderAssessmentForm = () => {
  const [isProviderAssessment, setIsProviderAssessment] = useState(true);
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-2 text-dark">
          Provider Assessment
          <span className="italic text-[#686868] text-base"> (Optional)</span>
        </h6>
        <BaseSwitch
          className="ml-4 mb-2"
          secondary={true}
          checked={isProviderAssessment}
          onChange={() => setIsProviderAssessment(!isProviderAssessment)}
        />
      </div>

      {isProviderAssessment && (
        <div className="grid grid-cols-2">
          <div className="col-span-2">
            <p className="leading-normal mb-4">
              Include an optional Provider Assessment to help evaluate providers
              that request this work order. Routed providers will not be asked
              for this additional information. You can create and manage
              Provider Assessments under the Manage menu.
              <span className="text-secondary cursor-pointer">
                {" "}
                Create a Provider Assessment
              </span>
            </p>
          </div>
          <div className="col-span-2">
            <BaseSelect
              className="mb-3"
              fullHeight={false}
              required={true}
              labelShrink={false}
              label="Provider Assessment"
              options={[]}
              name="qualificationType"
              isDefaultField={false}
              // value={formik.values.category}
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.category && Boolean(formik.errors.category)}
              // helpertext={formik.touched.category && formik.errors.category}
            />
          </div>
        </div>
      )}
    </BaseCard>
  );
};

export default ProviderAssessmentForm;
