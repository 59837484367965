import React from "react";

const FilterChip = ({ filter, count, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={` bg-white border-b rounded-lg shadow-md flex items-center py-[5px] px-9 justify-center min-w-[184px] cursor-pointer ${
        active ? "border-b  border-primary" : "bg-white"
      }`}
    >
      <p className={`${active ? "text-primary" : ""}`}>{filter}</p>
      <p
        className={`bg-[#EDEDED] rounded-md  py-[1px] px-2 ml-5 ${
          active ? "bg-white text-primary font-medium" : ""
        }`}
      >
        {count}
      </p>
    </div>
  );
};

export default FilterChip;
