import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const About = () => {
  return (
    <BaseCard className="py-4 px-16 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">About</h6>
      </div>
      <p>
        I am Adam Painter. I have a degree in IT: End user support. I have
        experience in performing field maintenance, delivering and installing
        printers, scanners, computers, cameras. I have leadership experience
        running projects, training other techs etc.
      </p>
    </BaseCard>
  );
};

export default About;
