import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDataFromLocalStorage,
  navigateWithReload,
  setDataInLocalStorage,
} from "../../utils/helpers";

const user = fetchDataFromLocalStorage("user");
const accessToken = fetchDataFromLocalStorage("accessToken");

const initialState = {
  value: {
    user: user ? user : {},
    accessToken: accessToken ? accessToken : "",
  },
};

const authUserSlice = createSlice({
  name: "authUserSlice",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.value = action.payload;
    },
    setLoginSuccessful: (state, action) => {
      setDataInLocalStorage("user", action.payload.user);
      setDataInLocalStorage("accessToken", action.payload.accessToken);

      setAuthUser({
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      });

      navigateWithReload("/");
    },
  },
});

export const { setAuthUser, setLoginSuccessful } = authUserSlice.actions;

export default authUserSlice.reducer;
