import { useState } from "react";
import { fetchDataFromLocalStorage } from "../utils/helpers";
import routes from "../routes";

const useAuth = () => {
  const user = fetchDataFromLocalStorage("user");

  const accessToken = fetchDataFromLocalStorage("accessToken");

  const [auth, setAuth] = useState({
    user: user,
    accessToken: accessToken,
  });

  const logout = () => {
    setAuth({ user: null });
  };

  const isAuthenticated = !!user && !!accessToken;

  const userModules = user?.role?.permissions?.map(
    (permission) => permission?.isRead && permission.module
  );

  const userPermissions = user?.role?.permissions?.map(
    (permission) => permission
  );

  const authRoutes = routes.filter((route) =>
    userModules?.includes(route.module)
  );

  return {
    auth,
    authRole: user?.role?.role,
    logout,
    isAuthenticated,
    userModules,
    userPermissions,
    authRoutes,
  };
};

export default useAuth;
