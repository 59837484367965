import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { brandPrimaryColor } from "../../../utils/variables";

// Styled component with dynamic styling based on props
const CustomSlider = styled(Slider)(
  ({ thumbColor, trackColor, railColor }) => ({
    "& .MuiSlider-thumb": {
      color: thumbColor || brandPrimaryColor,
    },
    "& .MuiSlider-track": {
      color: trackColor || brandPrimaryColor,
    },
  })
);

function valuetext(value) {
  return `${value}°C`;
}

// Reusable slider component
export default function BaseRange({
  defaultValue = 0, // Default slider value
  min = 0, // Minimum value for the slider
  max = 100, // Maximum value for the slider
  thumbColor, // Color for the thumb
  trackColor, // Color for the track
  railColor, // Color for the rail
  onChange,
  ...props // Other props for the slider
}) {
  return (
    <CustomSlider
      aria-label="Temperature"
      defaultValue={defaultValue}
      getAriaValueText={valuetext}
      onChange={onChange}
      min={min}
      max={max}
      {...props} // Pass any other props dynamically
    />
  );
}
