import { styled } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {
  brandFontFamily,
  brandInputTextColor,
  brandSecondaryColor,
} from "../../../utils/variables";

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontFamily: brandFontFamily,
    color: brandInputTextColor,
    fontSize: "14px",
    fontWeight: 300,
    marginTop: "1px",
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: `${brandSecondaryColor} !important`,
  "& .Mui-checked .MuiSvgIcon-root": {
    color: `${brandSecondaryColor} !important`,
  },
  /* "& .MuiRadio-root": {
    color: `${brandSecondaryColor} !important`,
  },
  "& .MuiButtonBase-root": {
    color: `${brandSecondaryColor} !important`,
  },
  "& .MuiButtonBase-root-MuiRadio-root": {
    color: `${brandSecondaryColor} !important`,
  }, */
  "& .MuiSvgIcon-root": {
    fontSize: "18px", // Change this value to adjust the size
  },
}));

const BaseRadio = ({ label, checked, value, defaultValue = "", ...props }) => {
  return (
    <CustomFormControlLabel
      value={value}
      control={<CustomRadio checked={checked} {...props} />}
      label={label}
      {...props}
    />
  );
};

const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
  fontFamily: brandFontFamily,
  color: brandInputTextColor,
  "& .MuiFormControlLabel-root": {
    marginBottom: "8px",
  },
}));

export default BaseRadio;
