import React from "react";
import WorkOrderStatus from "../../components/WorkOrderStatus";
import dayjs from "dayjs";

const TitleCard = ({ title, id, publishedDate, status }) => {
  return (
    <div className="grid grid-cols-10 items-center w-full">
      <div className="col-span-9 flex items-center">
        <div className="">
          <div className="flex items-center">
            <WorkOrderStatus status={status} textSize="text-sm" />
            <h6 className="font-normal ml-2">#{id}</h6>
            <p className="ml-2 text-sm">
              On {dayjs(publishedDate).format("MM/DD/YYYY")}
            </p>
          </div>
          <h6 className="font-normal mt-2">{title}</h6>
        </div>
      </div>
      <div className="border-r-[2px]  border-zinc-300 mr-6 h-full"></div>
    </div>
  );
};

export default TitleCard;
