import React from "react";
import BaseTextField from "../../../../components/ui/BaseTextField";

const BlendedPay = () => {
  return (
    <div className="grid grid-cols-2 gap-y-4 mt-3">
      <div className="mr-10">
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="title"
          name="Fixed Payment"
          label="Fixed Payment"
          optional={false}
          labelShrink={false}
          // value={formik.values.title}
          // onChange={formik.handleChange}

          isDefaultField={false}
        />
      </div>
      <div>
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="title"
          label="Fixed hours"
          optional={false}
          labelShrink={false}
          // value={formik.values.title}
          // onChange={formik.handleChange}

          isDefaultField={false}
        />
        <p className="mt-2 text-sm text-right">
          Let provider know how long they will be on the site
        </p>
      </div>
      <div className="mr-10">
        <BaseTextField
          fullWidth
          fullHeight={false}
          id="title"
          label="Additional Hourly Rate"
          optional={false}
          labelShrink={false}
          // value={formik.values.title}
          // onChange={formik.handleChange}

          isDefaultField={false}
        />
        <p className="mt-2 text-sm text-right">
          For hours exceeding the fixed hours
        </p>
      </div>

      <div>
        <BaseTextField
          fullWidth
          fullHeight={false}
          label="Approximate hours to complete"
          optional={false}
          labelShrink={false}
          // value={formik.values.title}
          // onChange={formik.handleChange}
          isDefaultField={false}
        />
        <p className="mt-2 text-sm text-right">
          Estimated Hours to Complete the Job
        </p>
      </div>
    </div>
  );
};

export default BlendedPay;
