import axios from "../../lib/axiosInstance";

export function createOffer(payload) {
  return axios.post("offers/request", payload).then((res) => res.data);
}

export function declineOffer(payload) {
  return axios.post("offers/not-interested", payload).then((res) => res.data);
}

export function fetchWorkOrderOffers(payload) {
  return axios.post(`offers/${payload.id}`, payload).then((res) => res.data);
}

export function assignWorkOrderToFreelancer(payload) {
  return axios.post(`offers/assign`, payload).then((res) => res.data);
}

export function updateWorkOrderStatus(payload) {
  return axios.post(`offers/status`, payload).then((res) => res.data);
}

export function routeOffer(payload) {
  return axios.post("offers/route", payload).then((res) => res.data);
}

export function deleteOffer(id) {
  return axios.delete(`offers/${id}`).then((res) => res.data);
}
