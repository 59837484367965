import React from "react";

const BaseDropdown = ({ children, open, className, style, ...props }) => {
  return (
    <>
      {open && (
        <div
          onClick={(e) => e.stopPropagation()}
          {...props}
          style={style}
          className={`${className} z-20 absolute top-4 left-[0px] bg-white  rounded-lg shadow-lg py-1 px-2 transition-all duration-300 ease-in-out transform ${
            open ? "opacity-100 scale-100" : "opacity-0 scale-95"
          }`}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default BaseDropdown;
