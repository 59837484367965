import React from "react";
import CustomTable from "../../../components/CustomTable";
import {
  assignWorkOrderToFreelancer,
  deleteOffer,
  fetchWorkOrderOffers,
  updateWorkOrderStatus,
} from "../../../services/api/offers";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showToast } from "../../../store/toast/toastSlice";
import BaseButton from "../../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../../utils/variables";
import { Avatar } from "@mui/material";
import BaseRating from "../../../components/ui/BaseRating";
import { useNavigate, useParams } from "react-router-dom";

const PendingAssignment = ({ filters }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    isLoading: isTechniciansLoading,
    error: isTechniciansError,
    data: freelancersData,
    refetch: refetchWorkOrderOffers,
  } = useQuery({
    queryKey: ["filteredFreelancers"],
    queryFn: () =>
      fetchWorkOrderOffers({
        id,
        filters: filters,
      }),
  });

  const {
    isPending: isOfferDeleteLoading,
    error: isOfferDeleteError,
    mutateAsync: deleteOfferMutation,
  } = useMutation({
    mutationFn: deleteOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "UnRouted Successfully",
          type: "success",
        })
      );
      refetchWorkOrderOffers();
    },
  });

  const {
    isPending: isAssignLoading,
    status,
    error: isAssignWorkOrderError,
    mutateAsync: assignWorkOrderToFreelancerMutation,
  } = useMutation({
    mutationFn: assignWorkOrderToFreelancer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Assign Successfully",
          type: "success",
        })
      );
      refetchWorkOrderOffers();
    },
  });

  const {
    isPending: isUpdateWorkOrderLoading,
    // status,
    error: isupdateWorkOrderStatusError,
    mutateAsync: updateWorkOrderStatusMutation,
  } = useMutation({
    mutationFn: updateWorkOrderStatus,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Updated Successfully",
          type: "success",
        })
      );
      refetchWorkOrderOffers();
    },
  });

  async function handleAssignWorkOrderButton(userId, workOrderId) {
    try {
      let payload = { userId, workOrderId };
      await assignWorkOrderToFreelancerMutation(payload);
    } catch (error) {
      dispatch(
        showToast({
          message: isupdateWorkOrderStatusError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  async function handleUpdateWorkOrderStatusButton(
    userId,
    workOrderId,
    status = "PENDING"
  ) {
    try {
      let payload = { userId, workOrderId, status };
      await updateWorkOrderStatusMutation(payload);
    } catch (error) {
      dispatch(
        showToast({
          message: isAssignWorkOrderError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  const data = freelancersData?.data || [];

  const COLUMNS = [
    {
      label: "Action",
      width: "300px",
      renderCell: (item) => (
        <div className="flex overflow-scroll">
          {!item.isCounter && item.status === "PENDING" && (
            <BaseButton
              loaderSize={18}
              loaderColor="primary"
              isLoading={isAssignLoading}
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
              onClick={() =>
                handleAssignWorkOrderButton(item.user.id, item.workOrderId)
              }
              className="bg-white hover:bg-primary hover:text-white text-primary text-sm  mr-3 w-[100px]"
            >
              Assign
            </BaseButton>
          )}
          {item.status === "PENDING" && (
            <BaseButton
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
              className="bg-white hover:bg-primary hover:text-white text-primary text-sm  mr-3 w-[100px]"
            >
              Hide
            </BaseButton>
          )}
          {item.isCounter && item.status === "PENDING" && (
            <BaseButton
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
              className="bg-white hover:bg-primary hover:text-white text-primary text-sm  mr-3 w-[100px]"
            >
              Review
            </BaseButton>
          )}

          {item.status === "ASSIGNED" && (
            <BaseButton
              loaderSize={18}
              loaderColor="primary"
              isLoading={isUpdateWorkOrderLoading}
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
              onClick={() =>
                handleUpdateWorkOrderStatusButton(
                  item.user.id,
                  item.workOrderId
                )
              }
              className="bg-white hover:bg-primary hover:text-white text-primary text-sm  mr-3 w-[110px]"
            >
              un-Assign
            </BaseButton>
          )}
          {item.status === "ROUTED" && (
            <BaseButton
              loaderSize={18}
              loaderColor="primary"
              isLoading={isOfferDeleteLoading}
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "3px",
                paddingBottom: "3px",
              }}
              f
              className="bg-white hover:bg-primary hover:text-white text-primary text-sm mr-3 w-[100px]"
              onClick={() => deleteOfferMutation(item.id)}
            >
              un-route
            </BaseButton>
          )}
          <BaseButton
            style={{
              border: `1px solid ${brandPrimaryColor}`,
              paddingTop: "3px",
              paddingBottom: "3px",
            }}
            f
            className="bg-white hover:bg-primary hover:text-white text-primary text-sm mr-3 w-[100px]"
          >
            Message
          </BaseButton>
        </div>
      ),
    },
    {
      label: "ID",
      renderCell: (item) => (
        <p className="text-sm text-primary">{item?.user?.id || item?.id}</p>
      ),
    },
    {
      label: "Name",
      renderCell: (item) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(`/profile/details/${item?.user.id}`)}
        >
          <Avatar src="/broken-image.jpg" sx={{ width: 32, height: 32 }} />
          <div className="ml-2">
            <p className="text-sm text-primary capitalize">
              {item?.user?.firstName} {item?.user?.lastName}
            </p>
            <p className="text-sm">{item?.user?.phoneNumber}</p>
          </div>
        </div>
      ),
    },
    {
      label: "Private Feedback",
      renderCell: (item) => <p className="text-sm">not enough data</p>,
    },
    {
      label: "Ratings",
      renderCell: (item) => (
        <div className="flex items-center justify-center border border-primary w-28 py-1 rounded-full">
          <BaseRating />
        </div>
      ),
    },
    {
      label: "Jobs",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="text-primary text-sm font-normal">336 </p>
          <p className="text-xs ml-1">Marketplace</p>
        </div>
      ),
    },
    {
      label: "Distance",
      renderCell: (item) => (
        <div className="flex items-center">
          <i className="fa-solid fa-car text-xs"></i>
          <p className="text-sm text-primary ml-2">12 Miles</p>
        </div>
      ),
    },
  ];

  return (
    <CustomTable columns={COLUMNS} data={data} currentFilter={filters[0]} />
  );
};

export default PendingAssignment;
