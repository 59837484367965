import React from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchCompanyUsers } from "../../../services/api/company";
import useAuth from "../../../hooks/useAuth";

const ContactsForm = () => {
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const contact = useSelector((state) => state.workOrder.scheduling.contacts);
  const {
    isLoading: companyUsersLoading,
    error: companyUsersLoadingError,
    data: companyUsersData,
  } = useQuery({
    queryKey: ["companyUsers"],
    queryFn: () => fetchCompanyUsers(auth.user.company.id),
    enabled: !!auth.user,
  });

  const formattedCompanyUsers = companyUsersData?.map((user) => {
    return {
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          contacts: {
            [name]: value,
          },
        },
      })
    );
  };

  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Contacts</h6>
      </div>
      <div className="grid grid-cols-2 gap-y-4">
        <div className="col-span-2">
          <BaseSelect
            fullHeight={false}
            fullWidth
            labelShrink={false}
            required={true}
            id="workOrderManagerId"
            name="workOrderManagerId"
            label="Work Order Manager"
            value={contact?.workOrderManagerId}
            onChange={handleChange}
            isDefaultField={false}
            options={formattedCompanyUsers}
          />
        </div>

        <div className="col-span-2 grid grid-cols-2 gap-y-4 bg-[#F6F6F6] p-5 rounded-lg">
          <div className="mr-10">
            <BaseTextField
              fullWidth
              fullHeight={false}
              id="title"
              name="title"
              label="Name or Description"
              optional={false}
              labelShrink={false}
              className="col-span-4"
              // value={formik.values.title}
              // onChange={formik.handleChange}

              isDefaultField={false}
            />
          </div>

          <div>
            <BaseSelect
              fullHeight={false}
              fullWidth
              label="Title"
              labelShrink={false}
              required={true}
              options={[]}
              name="clientId"
              // value={formik.values.clientId}
              // onChange={formik.handleChange}

              isDefaultField={false}
            />
          </div>
          <div className="mr-10">
            <BaseTextField
              fullWidth
              fullHeight={false}
              id="title"
              name="title"
              label="Phone"
              optional={false}
              labelShrink={false}
              className="col-span-4"
              // value={formik.values.title}
              // onChange={formik.handleChange}

              isDefaultField={false}
            />
          </div>

          <div>
            <BaseSelect
              fullHeight={false}
              fullWidth
              label="Email"
              labelShrink={false}
              required={false}
              options={[]}
              name="clientId"
              // value={formik.values.clientId}
              // onChange={formik.handleChange}
              isDefaultField={false}
            />
          </div>

          <div className="col-span-2">
            <BaseTextField
              fullWidth
              fullHeight={false}
              id="title"
              name="title"
              label="Note"
              required={false}
              labelShrink={false}
              className="col-span-4"
              // value={formik.values.title}
              // onChange={formik.handleChange}

              isDefaultField={false}
            />
          </div>
        </div>

        <div className="flex items-center cursor-pointer">
          <PlusIcon />
          <p className="text-secondary ml-2"> Add Contact</p>
        </div>
      </div>
    </BaseCard>
  );
};

export default ContactsForm;
