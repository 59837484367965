import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  summary: {
    overview: {
      templateId: null,
      title: "",
      clientId: null,
      projectId: null,
      isExportDisable: false,
      isCounterOffersDisable: false,
      isGpsRequire: false,
    },
    serviceDescription: {
      description: "",
      confidentialInformation: "",
    },
    workExperience: {
      categoryId: null,
      workOrderServices: [],
    },
  },
  scheduling: {
    schedule: {
      scheduleType: "HARD_START",
      orderStartDate: "",
      orderStartTime: "",
      timeZone: "EASTERN",
    },
    location: {
      displayName: "",
      locationType: "RESIDENTIAL",
      country: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      longitude: "",
      latitude: "",
      saveForFuture: false,
    },
    contacts: {
      workOrderManagerId: "",
    },
    qualification: {
      requiredQualifications: [],
    },
  },
  payment: {
    pay: {
      payType: "HOURLY",
      payAmount: 0,
      maxHours: 0,
      aproxHours: 0,
    },
  },
  assessment: {
    field7: "",
    field8: "",
  },
  workOrderDetails: {},
};

const workOrderSlice = createSlice({
  name: "workOrderSlice",
  initialState,
  reducers: {
    updateWorkOrderForm: (state, action) => {
      const { step, data } = action.payload;

      // Ensure that the state for the given step exists
      if (state[step]) {
        // Iterate through each key in the data object
        Object.keys(data).forEach((key) => {
          if (state[step][key]) {
            // Merge nested objects while preserving existing values
            state[step][key] = {
              ...state[step][key],
              ...data[key],
            };
          } else {
            // If the key is not a nested object, update it directly
            state[step] = {
              ...state[step],
              ...data,
            };
          }
        });
      }
    },
    setWorkOrderDetail: (state, action) => {
      state.workOrderDetails = action.payload;
    },
  },
});

export const { updateWorkOrderForm, setWorkOrderDetail } =
  workOrderSlice.actions;
export default workOrderSlice.reducer;
