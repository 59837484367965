import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Schedule = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-1 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex  pb-1 justify-between">
        <h6 className="text-dark">Schedule</h6>
        <div className="flex items-center bg-[#F2FAFF] py-1 px-4 rounded-md mb-2">
          <i class="fa-solid fa-circle-exclamation text-primary"></i>
          <p className=" ml-3 font-normal">Fixed Start</p>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 mt-6">
          <p>Start at a specific date and time</p>
          <p className="font-normal">Monday, 8/19/2024 at 9:00 AM (EDT)</p>
        </div>
        <div className="grid grid-cols-2  mt-4">
          <p>Approximate hours to complete</p>
          <p className="font-normal">3 hour(s)</p>
        </div>

        <p className="mt-8">
          Updated by <span className="text-primary">Colin Regan</span>
           on 8/12/2024 at 1:38 PM(EDT)
        </p>
      </div>
      <div className="border-t my-6"></div>

      <div>
        <div className="grid grid-cols-2 mt-6">
          <p>Confirmed Start Time</p>
          <p className="font-normal">Monday, 8/19/2024 at 9:00 AM (EDT)</p>
        </div>
        <div className="grid grid-cols-2  mt-4">
          <p>Duration</p>
          <p className="font-normal">3 hour(s)</p>
        </div>

        <p className="mt-8">
          Confirmed by <span className="text-primary">Ahmad Warsame</span>
           on 8/12/2024 at 1:38 PM(EDT)
        </p>
      </div>
    </BaseCard>
  );
};

export default Schedule;
