import React, { useState } from "react";

import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";

const WorkExperienceForm = () => {
  const dispatch = useDispatch();
  const workExperience = useSelector(
    (state) => state.workOrder.summary.workExperience
  );

  /* {
    serviceId: "0e505dc9-b863-42a4-a8f8-7cab647a76ba",
  },
  {
    serviceId: "945a8af6-0f41-4884-881a-4d454dd4a06c",
  }, */

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    dispatch(
      updateWorkOrderForm({
        step: "summary",
        data: {
          workExperience: {
            [name]: value,
          },
        },
      })
    );
  };
  return (
    <BaseCard className="pt-4 pb-10 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Work Experience</h6>
      </div>
      <div className="grid grid-cols-2">
        <div className="mr-10">
          <BaseSelect
            className="mb-3"
            placeholder="Select type of work..."
            fullHeight={false}
            required={true}
            labelShrink={false}
            label="Technology Stacks"
            options={[
              {
                label: "Secuirty License",
                value: 1,
              },
              {
                label: "Secuirty License",
                value: 2,
              },
            ]}
            name="categoryId"
            isDefaultField={false}
            value={workExperience.categoryId}
            onChange={handleChange}
          />
        </div>

        <div>
          <BaseSelect
            className="mb-2"
            labelShrink={false}
            label="Vendors"
            placeholder="Add additional types of work..."
            fullHeight={false}
            options={[]}
            required={true}
            isDefaultField={false}
          />
        </div>

        <div className="mr-10">
          <BaseSelect
            infoIcon={false}
            label="Products"
            fullHeight={false}
            labelShrink={false}
            required={true}
            placeholder="Add services..."
            options={[
              {
                label: "Secuirty License",
                value: 1,
              },
              {
                label: "Low Voltage License",
                value: 2,
              },
            ]}
            name="workOrderServices"
            multiple
            value={workExperience.workOrderServices}
            isDefaultField={false}
            onChange={handleChange}
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default WorkExperienceForm;
