import React, { useState } from "react";
import BaseButton from "../../../components/ui/BaseButton";
import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import BaseModal from "../../../components/ui/BaseModal";
import { brandPrimaryColor } from "../../../utils/variables";
import Pay from "./Pay";
import Schedule from "./Schedule";
import Expenses from "./Expenses";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "./style.css";
import { useMutation } from "@tanstack/react-query";
import { createOffer } from "../../../services/api/offers";
import { showToast } from "../../../store/toast/toastSlice";
import useAuth from "../../../hooks/useAuth";

const CounterOfferModal = ({
  counterOfferModalOpen,
  setCounterOfferModalOpen,
}) => {
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const dispatch = useDispatch();
  const { auth } = useAuth;
  const [counterOfferType, setCounterOfferType] = useState("PAY");

  const {
    status,
    error: isCreateOfferError,
    mutateAsync: createOfferMutation,
  } = useMutation({
    mutationFn: createOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Counter Offer Sent Successfully",
          type: "success",
        })
      );
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCounterOfferType(value);
  };

  const handleCounterButton = async () => {
    try {
      await createOfferMutation({
        workOrderId: workOrderDetail?.id,
        userId: auth?.user.id,
        isCounter: true,
      });
      setCounterOfferModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isCreateOfferError?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <BaseModal
      style={{
        width: "1200px",
      }}
      open={counterOfferModalOpen}
      close={() => {}}
    >
      <div className="container">
        <h6
          className="text-dark border-bottom"
          style={{ marginBottom: "24px" }}
        >
          Counter Offer
        </h6>

        <div>
          <div className="d-flex">
            <div className="schedule-detail-card bg-light">
              <div className="d-flex content-between">
                <p style={{ fontWeight: 400 }}>Current Schedule</p>
                <div>
                  <p style={{ textAlign: "right" }}>
                    {dayjs(workOrderDetail?.orderStartDate).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                  <p>
                    During{" "}
                    {dayjs(workOrderDetail?.orderStartTime).format("h:mm A")}
                  </p>
                </div>
              </div>
            </div>
            <div className="pay-detail-card bg-light d-flex content-between">
              <p style={{ fontWeight: 400 }}>Current Pay </p>

              <div>
                <div
                  className="d-flex align-items-center content-between"
                  style={{ width: "150px" }}
                >
                  <p style={{ fontSize: "0.75rem", fontWeight: 300 }}>
                    {workOrderDetail?.payType === "HOURLY" && "Hourly"}
                  </p>
                  <p>
                    {workOrderDetail?.payType === "HOURLY" &&
                      "$" + workOrderDetail?.payAmount + "/hour"}
                  </p>
                </div>
                <div
                  className="d-flex align-items-center content-between"
                  style={{ width: "150px" }}
                >
                  <p style={{ fontSize: "0.75rem", fontWeight: 300 }}>
                    Minimum
                  </p>
                  <p>
                    {(workOrderDetail?.payType === "HOURLY" &&
                      workOrderDetail?.allowedHours) ||
                      0 + " Hours"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="PAY"
            name="radio-buttons-group"
          >
            <BaseRadio
              label="Pay"
              value="PAY"
              name="payType"
              onChange={handleChange}
            />

            {counterOfferType === "PAY" && <Pay />}

            <BaseRadio
              label="Schedule"
              value="SCHEDULE"
              name="payType"
              onChange={handleChange}
            />
            {counterOfferType === "SCHEDULE" && <Schedule />}
            <BaseRadio
              label="Expenses"
              value="EXPENSES"
              name="payType"
              onChange={handleChange}
            />
            {counterOfferType === "EXPENSES" && <Expenses />}
          </RadioGroup>
        </div>

        <BaseButton className="add-expense-btn ">Add Expense</BaseButton>

        <div className="c-mt-2">
          <p style={{ marginBottom: "6px" }}>Reason Of Counter Offer</p>
          <textarea
            className="reason-card"
            // value={message}
            // onChange={handleMessage}
          />
        </div>

        <div className="d-flex content-center" style={{ marginTop: "60px" }}>
          <BaseButton
            className="button bg-light"
            style={{
              marginRight: "16px",
              color: "#686868",
            }}
            onClick={() => setCounterOfferModalOpen(false)}
          >
            Cancel
          </BaseButton>
          <BaseButton
            className="button"
            style={{
              background: brandPrimaryColor,
            }}
            onClick={handleCounterButton}
          >
            Submit
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default CounterOfferModal;
