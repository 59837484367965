import React from "react";
import CompanySignupForm from "./CompanySignupForm";
import BaseWrapper from "../../components/ui/BaseWrapper";
import HeroSection from "../../layouts/HeroSection";
import BaseInnerWrapper from "../../components/ui/BaseInnerWrapper";
import CompaniesSlider from "./CompaniesSlider";
import ServicesSection from "./ServicesSection";
import ReviewSection from "./ReviewSection";
import HorizontalBanner from "../../layouts/HorizontalBanner";
import CompanyHeroImg from "../../assets/company-signup/hero-section.png";
import review1 from "../../assets/company-signup/review-1.svg";
import review2 from "../../assets/company-signup/review-2.svg";
import review3 from "../../assets/company-signup/review-3.svg";
import BannerImage from "../../assets/company-signup/horizontal-banner.png";
import RightBackgroundImg from "../../assets/company-signup/hero-section-right.png";

const CompanySignup = () => {
  const reviews = [
    {
      description:
        "Tech Domain has revolutionized our operations, streamlined our workflow and connecting us with top-tier IT technicians effortlessly. It has significantly simplified the process of finding quality tech experts, enhancing our overall efficiency.",
      name: "Jayson Intal",
      position: "Business Development Manager",
      avatar: review1,
    },
    {
      description:
        "Thanks to Tech Domain, discovering highly skilled IT professionals has never been smoother. The platform's efficient process makes it incredibly easy to locate the right experts for our specific needs, ensuring we always have access to top-notch talent.",
      name: "Hardeep Lalli",
      position: "Director Operations Service Delivery",
      avatar: review2,
    },
    {
      description:
        "Tech Domain has transformed our operations by optimizing our workflow and seamlessly connecting us with elite IT technicians. It has greatly eased the process of locating top-notch tech experts, boosting our overall productivity.",
      name: "Ariishan Mahendra",
      position: "Operations Manager",
      avatar: review3,
    },
  ];
  return (
    <div className="h-screen">
      <HeroSection
        rightBackgroundImg={RightBackgroundImg}
        mainHeading={"We Bridge Talent & Tech \n Needs Seamlessly"}
        secondaryHeading="Quick, Reliable IT Experts at Your Fingertips"
        HeroSectionImg={CompanyHeroImg}
        backgroundColor="rgba(1, 33, 105, 0.61)"
      />

      <section className="absolute mt-16 w-screen top-[440px]">
        <CompanySignupForm />
        <BaseWrapper>
          <BaseInnerWrapper>
            <CompaniesSlider />
            <ServicesSection />
            <ReviewSection
              reviews={reviews}
              title="What Our Happy Users Says"
            />
          </BaseInnerWrapper>
        </BaseWrapper>
        <HorizontalBanner
          title="Find and Hire Top IT Technicians"
          description="Start leveraging the premier Tech Domain marketplace for IT field
            service professionals. Experience unparalleled efficiency and access
            to top-tier technicians, transforming how you manage your tech needs
            with ease and confidence. "
          backgroundImage={BannerImage}
          button1="Find Technicians"
          link1=""
          button2="Sign Up"
          link2=""
        />
      </section>
    </div>
  );
};

export default CompanySignup;
