import React from "react";
import { Avatar } from "@mui/material";
import "./style.css";
import dayjs from "dayjs";

function LeftBubble({ text, authUser, createdAt }) {
  return (
    <div className="mb-12">
      <div className="relative">
        <div class="absolute -left-[22px] bottom-[6px]">
          <Avatar src="/broken-image.jpg" />
        </div>
        <div class="bubble left flex items-center ">
          <p className="text-primary text-sm">{text}</p>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-primary capitalize">{`${authUser?.firstName} ${authUser?.lastName}`}</p>
        <p className="text-sm mr-6">
          {dayjs(createdAt).format("dddd, MM/D/YYYY") +
            " at " +
            dayjs(createdAt).format("h:mm A")}
        </p>
      </div>
    </div>
  );
}
function RightBubble({ text, authUser, createdAt }) {
  return (
    <div className="mb-12">
      <div className="relative">
        <div class="absolute -right-[22px] bottom-[6px]">
          <Avatar src="/broken-image.jpg" />
        </div>
        <div class="bubble right flex items-center">
          <p className="text-sm">{text}</p>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-sm ml-6">
          {dayjs(createdAt).format("dddd, MM/D/YYYY") +
            " at " +
            dayjs(createdAt).format("h:mm A")}
        </p>
        <p className="text-primary capitalize">{`${authUser?.firstName} ${authUser?.lastName}`}</p>
      </div>
    </div>
  );
}

const Chat = ({ sendMessage, handleMessage, messages, message, authUser }) => {
  return (
    <div className="bg-white rounded-lg shadow-md mb-40 flex flex-col max-h-[600px]">
      <div className="relative flex-1 overflow-y-auto">
        <div className="bg-white h-[70px] sticky top-0 z-10 w-full rounded-lg"></div>
        <div className="py-4 px-16">
          {messages?.length > 0 &&
            messages?.map((message) =>
              authUser?.id === message?.user?.id ? (
                <LeftBubble
                  text={message.text}
                  authUser={message.user}
                  key={message.id}
                  createdAt={message?.createdAt}
                />
              ) : (
                <RightBubble
                  text={message.text}
                  authUser={message.user}
                  key={message.id}
                  createdAt={message?.createdAt}
                />
              )
            )}
          {/* <LeftBubble />
          
          <LeftBubble />
          <RightBubble />
          <LeftBubble /> */}
        </div>
      </div>
      <div
        className="bg-white w-full rounded-b-lg"
        style={{
          boxShadow: "0 -2px 20px -12px #999999",
        }}
      >
        <div className="py-4 px-16 flex items-center">
          <textarea
            value={message}
            className="bg-[#F5F5F5] w-full rounded-lg h-28 resize-none p-4"
            onChange={handleMessage}
          />
          <i
            style={{ cursor: "pointer" }}
            className="fa-solid fa-paper-plane text-primary text-3xl ml-3 cursor-pointer"
            onClick={sendMessage}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default Chat;
