export function setDataInLocalStorage(key, payload) {
  localStorage.setItem(key, JSON.stringify(payload));
}

export function fetchDataFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function navigateWithReload(path) {
  window.location.href = path; // Navigate to the new route with a full reload
}

export function debounce(func, delay) {
  let timeoutId;

  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}
