import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const CustomField = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Provider Custom Field</h6>
      </div>
    </BaseCard>
  );
};

export default CustomField;
