import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const ServiceDescription = ({ workOrderDetail }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Service Description</h6>
        <i class="fa-solid fa-pencil ml-3"></i>
      </div>
      <div
        className="min-h-72"
        dangerouslySetInnerHTML={{ __html: workOrderDetail?.description }}
      ></div>
    </BaseCard>
  );
};

export default ServiceDescription;
