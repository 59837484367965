import React from "react";

import WorkOrderListingHeader from "./WorkOrderListingHeader";

import WorkOrderTable from "./WorkOrderTable";

const WorkOrderList = () => {
  return (
    <div className="bg-[#F6F6F6] h-screen">
      <WorkOrderListingHeader />
      <WorkOrderTable />
    </div>
  );
};

export default WorkOrderList;
