import { useLocation } from "react-router-dom";
import CompanySignup from "./CompanySignup";
import TechnicianSignup from "./TechnicianSignup";

const Signup = () => {
  const location = useLocation();

  return location.pathname === "/technician-signup" ? (
    <TechnicianSignup />
  ) : location.pathname === "/company-signup" ? (
    <CompanySignup />
  ) : (
    ""
  );
};

export default Signup;
