import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(({ theme, secondary }) => ({
  width: 32,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: secondary
          ? theme.palette.secondary.main
          : theme.palette.mode === "dark"
          ? "#177ddc"
          : theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    height: 14,
    backgroundColor: "#fff",
    borderRadius: "50%",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary.main
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function BaseSwitch({
  checked,
  onChange,
  secondary,
  className,
}) {
  return (
    <AntSwitch
      className={className}
      secondary={secondary}
      checked={checked}
      onChange={onChange}
      inputProps={{ "aria-label": "ant design" }}
    />
  );
}
