import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Shipments = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Shipments</h6>
      </div>
      <div>
        <div className="flex items-center mt-4 cursor-pointer">
          <i class="fa-solid fa-plus text-secondary"></i>
          <p className="text-secondary text-sm ml-1"> Add Shipment</p>
        </div>
      </div>
    </BaseCard>
  );
};

export default Shipments;
