import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Tasks = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Tasks</h6>
        <i class="fa-solid fa-pencil ml-3"></i>
      </div>
      <div>
        <div>
          <p>Prep</p>
          <div className="flex items-center bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2">
            <i class="fa-regular fa-circle text-xs"></i>
            <p className="ml-3 text-sm">Set start time</p>
          </div>
        </div>
        <div className="mt-5">
          <p>On Site</p>
          <div className="flex items-center bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2">
            <i class="fa-regular fa-circle text-xs"></i>
            <p className="ml-3 text-sm">Check in</p>
          </div>
          <div className="flex items-center bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2.5">
            <i class="fa-regular fa-circle text-xs"></i>
            <p className="ml-3 text-sm">Check out</p>
          </div>
        </div>
        <div className="mt-5">
          <p>Post</p>
          <div className="flex items-center bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2">
            <i class="fa-regular fa-circle text-xs"></i>
            <p className="ml-3 text-sm">Enter closeout notes</p>
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default Tasks;
