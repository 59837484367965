import React, { useState } from "react";
import BaseModal from "../../components/ui/BaseModal";
import OTP from "./Otp";
import BaseButton from "../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { verifyOtp } from "../../services/api/auth";
import { useDispatch } from "react-redux";
import { navigateWithReload, setDataInLocalStorage } from "../../utils/helpers";
import { setAuthUser } from "../../store/authUser/authUserSlice";
import "./style.css";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "40px 80px",
  textAlign: "center",
};
const classes = {
  verifyButton: { width: "100%", marginTop: "18px" },
  description: { marginBottom: "20px" },
  heading: { marginBottom: "6px" },
};
const OtpModal = ({ open, setOpen, email }) => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  const {
    isPending: isVerifyOtpLoading,
    status,
    error,
    mutateAsync: otpMutation,
  } = useMutation({
    mutationFn: verifyOtp,
    onSuccess: (data) => otpVerifiedSuccessfully(data),
  });

  const otpVerifiedSuccessfully = (response) => {
    if (otp === "") return;
    setDataInLocalStorage("user", response.data.user);
    setDataInLocalStorage("accessToken", response.data.accessToken);

    dispatch(
      setAuthUser({
        user: response.data.user,
        accessToken: response.data.accessToken,
      })
    );
    navigateWithReload("/work-orders");
  };

  return (
    <BaseModal open={open} close={() => setOpen(true)}>
      <div style={style}>
        <h2 style={classes.heading} className="font-500">
          OTP Verification
        </h2>
        <p style={classes.description}>
          We have sent a One-Time Password (OTP) code to the email
          <span className="font-500"> {email}</span>
        </p>
        <OTP value={otp} onChange={setOtp} length={6} />

        <BaseButton
          isLoading={isVerifyOtpLoading}
          onClick={() => {
            otpMutation({ code: otp, email });
          }}
          style={classes.verifyButton}
        >
          Verify
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default OtpModal;
