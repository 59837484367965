import React from "react";

const ScheduleCard = () => {
  return (
    <div className="bg-[#FFF2E8] rounded-md py-3 px-4 h-full min-h-28">
      <div className="flex">
        <i className="fa-solid fa-clock mt-1"></i>
        <div className="ml-2">
          <p className="font-normal">Schedule: Hard Time</p>
          <p className="text-sm mt-1">8/19/2024 at 9:00 AM(EDT)</p>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
