import { Avatar } from "@mui/material";
import React from "react";
import BaseButton from "../../components/ui/BaseButton";

const UserDetailsCard = ({
  authRole,
  createdBy,
  workOrderManager,
  offersCount,
}) => {
  return (
    <div className="bg-[#EEF5FF] rounded-md py-3 px-4 h-full min-h-28 flex flex-col justify-between">
      <div className="flex items-center">
        <Avatar
          src="https://img.freepik.com/free-photo/front-view-smiley-business-man_23-2148479583.jpg?t=st=1725259431~exp=1725263031~hmac=698528c51f80409024274dce1d7606f1b669320d2d11a09e75f7fa4f32a81b56&w=1380"
          sx={{ width: 36, height: 36 }}
        />
        {authRole === "FREELANCER" && (
          <div className="ml-2">
            <p className="font-normal">Manager/Site contact</p>

            <p className="text-sm text-primary capitalize">
              {workOrderManager
                ? workOrderManager?.firstName + " " + workOrderManager?.lastName
                : createdBy?.firstName + " " + createdBy?.lastName}
            </p>
          </div>
        )}

        {authRole === "CLIENT" && (
          <div className="ml-2">
            <p className="font-normal">Provider: unassigned</p>

            <p className="text-sm text-primary capitalize">
              {offersCount?.requested !== 0 &&
                offersCount?.requested + " Requests, "}
              {offersCount?.counter !== 0 &&
                offersCount?.counter + " Counters, "}
              {offersCount?.routed !== 0 && offersCount?.routed + " Routed"}
            </p>
          </div>
        )}
      </div>
      {authRole === "CLIENT" && (
        <div className="self-end">
          <BaseButton
            className="pt-1 pb-1 bg-white text-primary pl-2 pr-2 text-xs "
            fullWidth={false}
          >
            Leave Feedback
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default UserDetailsCard;
