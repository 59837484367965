// hooks/useUserLocation.js

import { useState, useEffect } from "react";
import axios from "axios";

const useUserLocation = (apiKey) => {
  const [locationDetails, setLocationDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by this browser.");
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          );

          if (response.data.results.length > 0) {
            const location = response.data.results[0];
            const addressComponents = location.address_components;

            const details = {
              formattedAddress: location.formatted_address,
              city:
                addressComponents.find((component) =>
                  component.types.includes("locality")
                )?.long_name || "N/A",
              state:
                addressComponents.find((component) =>
                  component.types.includes("administrative_area_level_1")
                )?.long_name || "N/A",
              country:
                addressComponents.find((component) =>
                  component.types.includes("country")
                )?.long_name || "N/A",
              postalCode:
                addressComponents.find((component) =>
                  component.types.includes("postal_code")
                )?.long_name || "N/A",

              latitude,
              longitude,
            };

            setLocationDetails(details);
          } else {
            setLocationDetails({
              formattedAddress: "Address not found",
              city: "N/A",
              state: "N/A",
              country: "N/A",
              postalCode: "N/A",
            });
          }
        } catch (err) {
          setError("Error fetching location data: " + err.message);
        } finally {
          setLoading(false);
        }
      },
      (err) => {
        setError("Geolocation error: " + err.message);
        setLoading(false);
      }
    );
  }, [process.env.REACT_APP_GOOGLE_API_KEY]);

  return { userCurrentLocation: locationDetails, error, loading };
};

export default useUserLocation;
