import * as React from "react";

import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { useSort } from "@table-library/react-table-library/sort";
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useRowSelect } from "@table-library/react-table-library/select";

import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/material-ui";

const BaseDataTable = ({
  columns = [],
  nodes,
  columnWidths,
  lastColFix = true,
  select,
}) => {
  const data = { nodes };

  const materialTheme = getTheme({
    ...DEFAULT_OPTIONS,
    striped: true,
    highlightOnHover: false,
    select: true,
  });
  const customTheme = {
    Table: `
      --data-table-library_grid-template-columns:  ${columns
        .map((col) => columnWidths[col.label] || "1fr")
        .join(" ")};
      border-radius: 6px;
      border: 1px solid #DEDEDE;
    `,
    HeaderCell: `
    border-right: 1px solid #8C8C8C;
    height: 36px;
    
      
        &:nth-of-type(1) {
        left: 0px;
        border-right: 1px solid #8C8C8C;
      
      }
    font-weight: 400;
    font-size: 16px;
    .active {
      font-weight: 400 !important;
    }
    `,
    Cell: `
    height: 58px;
    font-weight: 300;
    `,
    BaseCell: `
      
        &:nth-of-type(1) {
        left: 0px;
        border-right: 1px solid #8C8C8C;
      }
      
      &:nth-of-type(${columns.length}) ${
      lastColFix
        ? `{
        right: 0;
        left: auto;  /* Ensure last xscolumn sticks to the right */
      }`
        : `
        padding:0px;
        `
    }
    `,
  };

  const theme = useTheme([materialTheme, customTheme]);
  function onSortChange(action, state) {
    console.log(action, state);
  }

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortIcon: {
        iconDefault: <i class="fa-solid fa-arrow-up-wide-short"></i>,
        iconUp: <i class="fa-solid fa-arrow-up-wide-short"></i>,
        iconDown: <i class="fa-solid fa-arrow-down-wide-short"></i>,
      },
      sortFns: {
        TASK: (array) =>
          array.sort((a, b) => (a.name || "").localeCompare(b.name || "")),
        /* DEADLINE: (array) => array.sort((a, b) => a.deadline - b.deadline),
        TYPE: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
        COMPLETE: (array) => array.sort((a, b) => a.isComplete - b.isComplete),
        TASKS: (array) =>
          array.sort((a, b) => (a.nodes || []).length - (b.nodes || []).length), */
      },
    }
  );

  return (
    <>
      {columns.length && (
        <CompactTable
          columns={columns}
          data={data}
          theme={theme}
          sort={sort}
          layout={{ custom: true, horizontalScroll: true }}
          select={select}
        />
      )}
    </>
  );
};

export default BaseDataTable;
