import React, { useEffect, useState } from "react";
import BaseWrapper from "../../components/ui/BaseWrapper";
import dayjs from "dayjs";
import WorkOrderStatus from "../../components/WorkOrderStatus";
import BaseDataTable from "../../components/ui/BaseDataTable";
import FilterChip from "../../components/FilterChip";
import { useNavigate } from "react-router-dom";

const FindJobTable = ({ jobs, setJobsFilters, jobsCount }) => {
  const [filters, setFilters] = useState("AVAILABLE");
  const [statusCount, setStatusCount] = useState({});

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/find-job/${id}`);
  };

  const data = jobs || [];
  const columnWidths = {
    ID: "206px",
    "Job Title": "312px",
    Location: "18%",
    "Type of work": "14%",
    Schedule: "14%",
    Pay: "16%",
    // Distance: "14%",
    // Company: "14%",
    // "Buyer Rating": "14%",
    // Requests: "14%",
    Status: "22%",

    // "Additional Column": "70%",
  };
  const COLUMNS = [
    {
      label: "ID",
      renderCell: (item) => (
        <p className="text-sm font-normal text-primary">{item.id}</p>
      ),
      pinLeft: false,
      sort: { sortKey: "ID" },
    },
    {
      label: "Job Title",
      renderCell: (item) => (
        <>
          <p
            className="text-sm text-primary cursor-pointer"
            onClick={() => handleNavigate(item.id)}
          >
            {item.title}
          </p>
        </>
      ),
      // sort: { sortKey: "TITLE" },
    },
    {
      label: "Location",
      renderCell: (item) => (
        <p className="text-sm">{`${item.address.addressLine1}, ${item.address.country}`}</p>
      ),
      // sort: { sortKey: "LOCATION" },
    },

    {
      label: "Schedule",
      renderCell: (item) => (
        <div>
          <p className="text-sm">
            {dayjs(item.orderStartDate).format("MM/DD/YYYY")}

            <span> at </span>

            {dayjs(item.orderStartTime).format("hh:mm A")}
          </p>
          <p className="text-primary text-xs">
            {item.scheduleType === "HARD_START" ? "Hard Start" : ""}
          </p>
        </div>
      ),
      // sort: { sortKey: "SCHEDULE" },
    },
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">${item.payAmount}</p>
          <p className="text-sm">
            ${item.payType === "HOURLY" ? "Hourly" : "Fixed"}
          </p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Status",
      renderCell: (item) => (
        <WorkOrderStatus
          status={
            filters === "AVAILABLE"
              ? item.status
              : item?.offers?.isCounter
              ? "COUNTER"
              : item?.offers?.status
          }
        />
      ),
      // sort: { sortKey: "STATUS" },
    },
    /* 
    

    
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Distance",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Company",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Buyer Rating",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Requests",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },

     */
  ];

  return (
    <BaseWrapper>
      {/* <div className="bg-white rounded-lg p-5 shadow-md my-4">
        <div className="flex">
          <div className="flex items-center border-r-[1px] border-[#8C8C8C] pr-5">
            <i className="fa-solid fa-circle-plus text-secondary text-[22px]"></i>
            <p className="text-secondary ml-2">Add Filter</p>
          </div>
          <div className="flex items-center pl-5">
            <p className="font-medium">
              Assigned Provider:{" "}
              <span className="font-light">Samuel Nyandoro</span>
            </p>
            <i className="ml-3 mb-[2px] fa-solid fa-chevron-down"></i>
          </div>
        </div>
      </div> */}

      <div className="py-4 flex">
        <FilterChip
          filter="Available"
          count={jobsCount?.AVAILABLE || 0}
          active={filters === "AVAILABLE" ? true : false}
          onClick={() => {
            setFilters("AVAILABLE");
            setJobsFilters({
              status: "AVAILABLE",
            });
          }}
        />

        <div className="ml-8">
          <FilterChip
            filter="Routed"
            count={jobsCount?.ROUTED || 0}
            active={filters === "DRAFT" ? true : false}
            onClick={() => {
              setFilters("DRAFT");
              setJobsFilters({
                status: "ROUTED",
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Counter"
            count={jobsCount?.COUNTER || 0}
            active={filters === "PUBLISHED" ? true : false}
            onClick={() => {
              setFilters("PUBLISHED");
              setJobsFilters({
                status: "PENDING",
                isCounter: true,
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Assigned"
            count={jobsCount?.ASSIGNED || 0}
            active={filters === "ASSIGNED" ? true : false}
            onClick={() => {
              setFilters("ASSIGNED");

              setJobsFilters({
                status: "ASSIGNED",
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Completed"
            count={statusCount.DONE ? statusCount.DONE : 0}
            active={filters === "DONE" ? true : false}
            onClick={() => {
              setFilters("DONE");

              setJobsFilters({
                status: "DONE",
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Decline"
            count={jobsCount?.DECLINED || 0}
            active={filters === "APPROVED" ? true : false}
            onClick={() => {
              setFilters("APPROVED");

              setJobsFilters({
                status: "DECLINED",
              });
            }}
          />
        </div>
      </div>

      <BaseDataTable
        columns={COLUMNS}
        nodes={data}
        columnWidths={columnWidths}
        lastColFix={false}
      />
    </BaseWrapper>
  );
};

export default FindJobTable;
