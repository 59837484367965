import React, { useEffect, useState } from "react";
import Providers from "../TabsCard/Providers";

import BaseCard from "../../../components/ui/BaseCard";
import BaseButton from "../../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../../utils/variables";

const ProvidersTab = () => {
  return (
    <div>
      <BaseCard elevation={0} className="border py-3 px-10 rounded-lgs">
        <div>
          <BaseButton
            style={{
              border: `1px solid ${brandPrimaryColor}`,
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            className="hover:bg-primary bg-white hover:text-white text-primary text-sm  w-[150px]"
          >
            Request
          </BaseButton>
          <BaseButton
            style={{
              border: `1px solid ${brandPrimaryColor}`,
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            className="hover:bg-primary bg-white hover:text-white text-primary text-sm  w-[180px] ml-6"
          >
            Invite to Recruitment
          </BaseButton>
        </div>
        <div>{/* <BaseTextField /> */}</div>
      </BaseCard>
      <Providers />
    </div>
  );
};

export default ProvidersTab;
