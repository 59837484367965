import React from "react";
import BaseWrapper from "../../components/ui/BaseWrapper";
import BaseInnerWrapper from "../../components/ui/BaseInnerWrapper";
import BaseButton from "../../components/ui/BaseButton";
import { Link } from "react-router-dom";

const HorizontalBanner = ({
  backgroundImage,
  title,
  description,
  button1,
  link1,
  button2,
  link2,
}) => {
  const overlayColor = "rgba(1, 33, 105, 0.9)"; // Blue color with 50% opacity
  const style = {
    backgroundImage: `linear-gradient(${overlayColor}, ${overlayColor}), url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={style}>
      <BaseWrapper>
        <BaseInnerWrapper>
          <h3 className="text-white text-center py-7">{title}</h3>
          <p className="text-white text-center leading-normal">{description}</p>
          <div className="flex justify-center py-7 ">
            <Link to={link1}>
              <BaseButton
                className="min-w-56 mr-3 rounded-lg"
                variant="outlined"
                type="submit"
              >
                <p className="text-sm font-semibold	font-semibold text-white px-7">
                  {button1}
                </p>
              </BaseButton>
            </Link>
            <Link to={link2}>
              <BaseButton
                className="min-w-56 ml-3 rounded-lg shadow-lg "
                color="secondary"
                variant="contained"
                type="submit"
                hoverLight={true}
              >
                <p className="text-sm font-semibold	font-semibold text-white px-7">
                  {button2}
                </p>
              </BaseButton>
            </Link>
          </div>
        </BaseInnerWrapper>
      </BaseWrapper>
    </div>
  );
};

export default HorizontalBanner;
