import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/global-icons/remove-icon.svg";
import BaseSwitch from "../../../components/ui/BaseSwitch";
import BaseDragDrop from "../../../components/ui/BaseDragDrop";
import BaseButton from "../../../components/ui/BaseButton";

const DocumentsForm = () => {
  const [isSelectionRules, setIsSelectionRules] = useState(true);
  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-2 text-dark">
          Customer Files{" "}
          <span className="italic text-[#686868] text-base">(Optional)</span>
        </h6>
        <BaseSwitch
          className="ml-4 mb-2"
          secondary={true}
          checked={isSelectionRules}
          onChange={() => setIsSelectionRules(!isSelectionRules)}
        />
      </div>

      {isSelectionRules && (
        <div className="grid grid-cols-2 gap-8 py-3">
          <div>
            <BaseDragDrop>
              <div className="flex items-center">
                <BaseButton
                  textSize="text-sm"
                  variant="outlined"
                  className="shadow-none w-28"
                  style={{ padding: "8px 20px" }}
                >
                  Add File
                </BaseButton>
                <p className="ml-8">or drag and drop here to upload</p>
              </div>
            </BaseDragDrop>

            <div className="flex my-5">
              <BaseSwitch secondary />
              <p className="text-xs ml-2">
                supplier can view these files even before the work assignement
              </p>
            </div>

            <div className="bg-[#F6F6F6] rounded-md mt-6">
              <div className="flex justify-between px-4 py-2">
                <div className="flex items-center">
                  <img
                    className="h-14	w-14 rounded-md"
                    src="https://as2.ftcdn.net/v2/jpg/05/21/18/03/1000_F_521180377_2iAVJqBQSo3cgKaVp8vMBR8asrC61DoU.jpg"
                  />
                  <div className="ml-3">
                    <p className="font-normal">image_9.png</p>
                    <p>7/23/2024 at 2:43 AM EDT</p>
                    <p>
                      Uploaded by: 
                      <span className="text-secondary">Istkhar Hussain</span>
                    </p>
                  </div>
                </div>
                <div>
                  <i className="fa-solid fa-download"></i>
                  <i className="fa-solid fa-trash-can ml-3"></i>
                </div>
              </div>
            </div>
          </div>

          <div>
            <BaseDragDrop>
              <div className="flex items-center">
                <BaseButton
                  textSize="text-sm"
                  variant="outlined"
                  className="shadow-none w-36 "
                  style={{ padding: "8px 12px" }}
                >
                  Document library
                </BaseButton>
                <p className="ml-8">find a file you already uploaded</p>
              </div>
            </BaseDragDrop>

            <div className="bg-[#F6F6F6] rounded-md mt-14">
              <div className="flex justify-between px-4 py-2">
                <div className="flex items-center">
                  <img
                    className="h-14	w-14 rounded-md"
                    src="https://as2.ftcdn.net/v2/jpg/05/21/18/03/1000_F_521180377_2iAVJqBQSo3cgKaVp8vMBR8asrC61DoU.jpg"
                  />
                  <div className="ml-3">
                    <p className="font-normal">image_9.png</p>
                    <p>7/23/2024 at 2:43 AM EDT</p>
                    <p>
                      Uploaded by: 
                      <span className="text-secondary">Istkhar Hussain</span>
                    </p>
                  </div>
                </div>
                <div>
                  <i className="fa-solid fa-download"></i>
                  <i className="fa-solid fa-trash-can ml-3"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </BaseCard>
  );
};

export default DocumentsForm;
