import React from "react";
import ScheduleForm from "../Forms/ScheduleForm";
import LocationForm from "../Forms/LocationForm";
import ContactsForm from "../Forms/ContactsForm";
import QualificationForm from "../Forms/QualificationForm";
import ShipmentsForm from "../Forms/ShipmentsForm";
import ProviderAssessmentForm from "../Forms/ProviderAssessmentForm";
import SelectionRulesForm from "../Forms/SelectionRulesForm";
import SmartAuditForm from "../Forms/SmartAuditForm";

const Assessment = () => {
  return (
    <div>
      <ShipmentsForm />
      <ProviderAssessmentForm />
      <SelectionRulesForm />
      <SmartAuditForm />
    </div>
  );
};

export default Assessment;
