import React from "react";
import HeroSection from "../../layouts/HeroSection";
import TechnicianHeroImg from "../../assets/technician-signup/hero-section-img.png";
import TechnicianSignupForm from "./TechnicianSignupForm";
import BaseWrapper from "../../components/ui/BaseWrapper";
import BaseInnerWrapper from "../../components/ui/BaseInnerWrapper";
import ReviewSection from "./ReviewSection";
// import reviewIcon from "../../assets/technician-signup/review.svg";
import HorizontalImg from "../../assets/technician-signup/horizontal-img.png";
import HorizontalBanner from "../../layouts/HorizontalBanner";

const TechnicianSignup = () => {
  const reviews = [
    {
      description:
        "Tech Domain values me and listens to my needs. Loyalty is a two-way street, and their staff is always available when I need them, though I rarely do.",
      name: "Peter Falana",
      position: "Technician",
      // avatar: reviewIcon,
    },
  ];
  return (
    <div className="h-screen">
      <HeroSection
        backgroundColor="#F6F6F6"
        mainHeading={"Unleash your in-demand \n skills today!"}
        secondaryHeading={
          "Kickstart Your Journey with a \n Free Tech Domain Account!"
        }
        HeroSectionImg={TechnicianHeroImg}
        textColor="#0F40AE"
      />

      <section className="absolute mt-16 w-screen top-[440px]">
        <TechnicianSignupForm />

        <BaseWrapper>
          <BaseInnerWrapper>
            <ReviewSection
              reviews={reviews}
              avatarCenter={true}
              title="Need Assistance? We're Here to Help"
            />
          </BaseInnerWrapper>
        </BaseWrapper>
        <HorizontalBanner
          title="Build your business with Tech Domain"
          description="Join now if you're prepared to begin working. Do you still have questions? Learn more about Field Nation's plans, costs, insurance, and other topics. We are here to support your success in the industry."
          backgroundImage={HorizontalImg}
          button1="Learn More"
          link1=""
          button2="Sign Up"
          link2=""
        />
      </section>
    </div>
  );
};

export default TechnicianSignup;
